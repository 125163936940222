import React, { useState } from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
import HeroCard from "../../components/HeroCard";
import { DevMode } from "@components/DevMode";

export const VolunteerNewInfo = () => {
  return (
    <>
      <HeroCard>
        <HeroCard.Header>Informatie vrijwilliger</HeroCard.Header>
        <HeroCard.Body>
          <p>Bedankt om je mee in te zetten!</p>
        </HeroCard.Body>
        <HeroCard.Actions>
          <button className="btn btn-primary">Verder gaan</button>
        </HeroCard.Actions>
      </HeroCard>
      <DevMode>
        <Link className="btn btn-primary" to="/trainer-voordelen">
          Trainer
        </Link>
        <Link className="btn btn-primary" to="/familie-informatie">
          Niet trainer
        </Link>
      </DevMode>
    </>
  );
};
