import React, { useMemo, useRef, useState } from "react";
import FormCard from "../../components/FormCard";
import { useLocation, useHistory, Link } from "react-router-dom";
import { DevMode } from "../../components/DevMode";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useEffect } from "react";
import { useTimeout } from "react-use-timeout";
import { apiPath, wpPath } from "../../store/utils";
import wretch from "wretch";
import { SpinnerOverlay } from "../../components/SpinnerOverlay";
import { startRegistration } from "../../store/registration/registrationSlice";
import { Alert } from "../../components/Alert";
import uuid from "react-uuid";
import { makeString } from "../../utils/StringUtils";
import HCaptcha from "@hcaptcha/react-hcaptcha";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const SubjectIdentificationForm = () => {
  let query = useQuery();
  const dispatch = useDispatch();
  const [callPending, setCallPending] = useState(false);
  const [errors, seterrors] = useState(null);
  const loggedInUser = useSelector((state) => state.user);
  // const userProfile = useSelector((state) => state.profile);
  const ref = useRef(null);

  const hasEmail = useMemo(() => {
    return query.get("email") === "yes";
  }, [query]);

  //   let defEmail = "";
  //   if (!hasEmail)
  //     defEmail =
  //       "vos-" + Math.round(Math.random() * 10000).toString() + "@vosreinaert.be";

  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    token: "",
    forwarder: loggedInUser.email,
  });

  function onVerifyCaptcha(token) {
    setUser((user) => ({ ...user, token }));
  }

  const generatedEmail = useMemo(() => {
    return (
      user.first_name +
      "." +
      user.last_name +
      "@vosreinaert.be"
    ).replaceAll(" ", "");
  }, [user]);

  const checkUser = () => {
    setCallPending(true);
    wretch(apiPath("profile/search"))
      .post({
        search: hasEmail ? user.email : generatedEmail,
      })
      //   .notFound((error) => rejectWithValue({ code: 404 }))
      //   .error(400, (error) =>
      //     rejectWithValue({ code: 400, ...JSON.parse(error.message) })
      //   )
      .json((response) => {
        setCallPending(false);

        if (response.length > 0) {
          // Start new registration with user
          dispatch(startRegistration({ user_ID: response[0] })).then(() =>
            history.push("/geboortejaar")
          );
        } else {
          setCallPending(true);
          // Register first
          wretch(wpPath("registraties/handle-user.php"))
            .post({
              ...user,
              email: hasEmail ? user.email : generatedEmail,
              password: makeString(8),
              token: user.token,
            })
            .json(async (response) => {
              setCallPending(false);
              if (response.success) {
                dispatch(
                  startRegistration({
                    user_ID: response.user_ID,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: hasEmail ? user.email : generatedEmail,
                  })
                ).then(() => history.push("/geboortejaar"));
              } else {
                if (response.hasOwnProperty("msg")) seterrors([response.msg]);
                else seterrors(["Er is een fout met de ingave"]);
              }
            })
            .catch((e) => {
              setCallPending(false);

              seterrors(["Er is een fout met de ingave"]);
            });
        }
      });
    //   .catch((error) => rejectWithValue({ code: 500 }));
  };

  const history = useHistory();

  const save = (e) => {
    e.preventDefault();
    checkUser();
  };

  return (
    <>
      <FormCard onSubmit={save} enableBack={true}>
        <FormCard.Header>Aanmelden derde</FormCard.Header>
        <FormCard.Body>
          <p>
            Vul onderstaande informatie in, indien we het profiel al kennen kan
            dit automatisch gekoppeld worden.
          </p>
          {errors !== null && (
            <Alert type="error" className="mb-4">
              <div>
                {errors.map((err) => (
                  <span className="block" key={uuid()}>
                    {err}
                  </span>
                ))}
              </div>
            </Alert>
          )}
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">Voornaam</span>
            </label>

            <input
              type="text"
              placeholder="Tybeert"
              className="input input-bordered w-full"
              value={user.first_name}
              required
              onChange={(e) => {
                e.preventDefault();
                setUser((prev) => ({ ...prev, first_name: e.target.value }));
              }}
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Achternaam</span>
            </label>
            <input
              type="text"
              placeholder="De Kater"
              required
              className="input input-bordered"
              value={user.last_name}
              onChange={(e) => {
                e.preventDefault();
                setUser((prev) => ({ ...prev, last_name: e.target.value }));
              }}
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Email</span>
            </label>

            <input
              //   type="email"
              placeholder="tybeert@vosreinaert.be"
              className="input input-bordered"
              value={hasEmail ? user.email : generatedEmail}
              disabled={!hasEmail}
              onChange={(e) => {
                e.preventDefault();
                setUser((prev) => ({ ...prev, email: e.target.value }));
              }}
            />
          </div>
          {!hasEmail && (
            <div className="form-control">
              <label className="label">
                <span className="label-text">Doorsturen naar:</span>
              </label>
              <input
                type="email"
                className="input input-bordered"
                value={user.forwarder}
                onChange={(e) => {
                  e.preventDefault();
                  setUser((prev) => ({ ...prev, forwarder: e.target.value }));
                }}
              />
            </div>
          )}
          <HCaptcha
            sitekey="bad67710-191e-498c-8da5-d9a9fdaa0ebe"
            onVerify={onVerifyCaptcha}
          />
          {callPending && <SpinnerOverlay />}
        </FormCard.Body>
        <FormCard.Actions>
          <input
            type="submit"
            value="Volgende"
            disabled={callPending}
            className="btn btn-primary"
          />
        </FormCard.Actions>
      </FormCard>
      {/* <DevMode>
        <Link
          className="btn btn-primary btn-sm"
          to="/identiteit-bevestigen-derde"
        >
          Email al gekend
        </Link>
        <Link className="btn btn-primary btn-sm" to="/geboortejaar">
          Email niet gekend
        </Link>
      </DevMode> */}
    </>
  );
};
