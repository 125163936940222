import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import { Start } from "./identification/Start";
import { Login } from "./identification/Login";
import { Register } from "./identification/Register";
import { Lookup } from "./identification/Lookup";

// const config = require("@root/config.json");

export const loginMode = {
  START: 0,
  LOGIN: 1,
  REGISTREREN: 2,
  OPZOEKEN: 3,
};

let IdentificationForm = () => {
  const [mode, setMode] = useState(loginMode.START);
  const [email, setEmail] = useState("");

  const setModeEmail = (mode, email) => {
    setEmail(email);
    setMode(mode);
  };

  // const loggedIn = useSelector((state) => {
  //   return state.user.loggedIn;
  // });

  // const history = useHistory();

  // useEffect(() => {
  //   if (loggedIn) {
  //     history.push("/wie-inschrijven");
  //   }
  // }, [loggedIn, history]);

  return (
    <>
      {mode === loginMode.START && <Start setMode={setMode} />}
      {mode === loginMode.LOGIN && <Login setMode={setMode} email={email} />}

      {mode === loginMode.REGISTREREN && (
        <Register setMode={setMode} email={email} />
      )}
      {mode === loginMode.OPZOEKEN && (
        <Lookup setMode={setMode} setModeEmail={setModeEmail} />
      )}

      {/* <DevMode>
        <Link className="btn btn-primary btn-sm" to="/identiteit-bevestigen">
          Gekend
        </Link>
        <Link className="btn btn-primary btn-sm" to="/welkom">
          Niet gekend
        </Link>
      </DevMode> */}
    </>
  );
};

export { IdentificationForm };
