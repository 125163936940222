import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Card from "../../components/Card";
import { SportSelection } from "./SportSelection";
import { TEAMS_YOUTH } from "../../store/data-constants";
import { TeamSelectionForm } from "./TeamSelectionForm";

export const JuniorMembershipForm = () => {
  const history = useHistory();

  const [mode, setMode] = useState(0);
  const switchModes = (mode = 1) => {
    setMode(mode);
  };
  const handleNext = () => {
    history.push("/familie-informatie");
  };

  return (
    <>
      {mode === 0 && <SportSelection next={switchModes} />}
      {mode === 1 && (
        <TeamSelectionForm
          collection={TEAMS_YOUTH}
          back={switchModes}
          next={handleNext}
        />
      )}
    </>
  );
};
