import React from "react";

export const DevMode = ({ children }) => {
  if (process.env.NODE_ENV === "production") return null;
  else
    return (
      <div className="card shadow-lg card-compact  bg-accent text-accent-content fixed bottom-2 left-2">
        <div className="card-body">
          <div className="card-title text-sm uppercase text-center ">
            Dev mode
          </div>

          <div className="flex flex-col space-y-2">{children}</div>
        </div>
      </div>
    );
};
