import React from "react";
import { Link } from "react-router-dom";
import Card from "../components/Card";
// import { DevMode } from "../components/DevMode";

export const HomePage = () => {
  return (
    <>
      <Card>
        <Card.Header>Vos inschrijvingsportaal</Card.Header>
        <Card.Body>
          <div className="flex flex-col space-y-2">
            <p>
              Heel fijn dat je weer een jaartje voor de uniekste, grootste en
              tofste omnisportfamilie van het Waasland kiest!
              <br />
              Lid zijn van VOS is echt wel meer dan gewoon komen sporten. Het is
              een club voor en door de leden. Iedereen daar draagt daar op zijn
              manier een steentje bij.
            </p>
            <p>
              Hierna volgen enkele vragen over wie je bent en wat je graag doet.
              Neem er even je tijd voor zodat we jou en eventueel je kinderen op
              een correcte manier kunnen inschrijven. Zo hebben we in één klap
              alle info en kunnen we alles ineens afhandelen.{" "}
            </p>
            <p></p>
            <p>Bedankt voor je medewerking!</p>
          </div>
        </Card.Body>
        <Card.Actions>
          <Link
            to="/aanmelden"
            className="btn btn-primary px-10 w-full lg:w-auto"
          >
            Starten
          </Link>
        </Card.Actions>
      </Card>
      {/* <DevMode>
        <Link className="btn btn-primary btn-sm" to="/wie-inschrijven">
          Ingelogd
        </Link>
        <Link className="btn btn-primary btn-sm" to="/aanmelden">
          Niet ingelogd
        </Link>
      </DevMode> */}
    </>
  );
};
