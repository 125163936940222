import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const UseSkip = (pageName, skipRoute) => {
  const history = useHistory();

  useEffect(() => {
    if (history.action === "POP") return;
    if (sessionStorage.getItem(pageName) !== null) {
      history.push(skipRoute);
    } else {
      sessionStorage.setItem(pageName, 1);
    }
  }, [history, pageName, skipRoute]);
};
