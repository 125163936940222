import React, { Children } from "react";

function Header() {
  return null;
}

function Body() {
  return null;
}

function Actions() {
  return null;
}

class MediaCard extends React.Component {
  static Header = Header;
  static Body = Body;
  static Actions = Actions;
  constructor(props) {
    super(props);
    this.state = { playing: false };
    this.videoPlayer = React.createRef();
  }
  playVideo = (e) => {
    e.preventDefault();
    this.videoPlayer.current.play();
    this.setState({ playing: true });
  };
  videoEnded = (e) => {
    this.setState({ playing: false });
  };
  render() {
    const { children, maxSize = "6xl", className = "" } = this.props;

    const childNodes = Children.toArray(children);

    const header = childNodes.find((child) => child.type === Header);
    const body = childNodes.find((child) => child.type === Body);
    const actions = childNodes.find((child) => child.type === Actions);

    return (
      <div
        className={`card lg:card-side shadow-lg w-full lg:mx-5 md:max-w-${maxSize} ${className} bg-base-100`}
      >
        <figure className="relative lg:w-3/5">
          <video ref={this.videoPlayer} onEnded={this.videoEnded}>
            <source src={this.props.video} />
          </video>
          {!this.state.playing && (
            <button
              className="absolute bg-white shadow-md flex justify-center items-center -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 mask mask-squircle w-24 h-24"
              onClick={this.playVideo}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 64 64"
                aria-labelledby="title"
                aria-describedby="desc"
                role="img"
                className="stroke-current w-8 h-8"
              >
                <title>Play</title>
                <desc>A line styled icon from Orion Icon Library.</desc>
                <path
                  fill="none"
                  strokeMiterlimit="10"
                  strokeWidth="3"
                  d="M6 2l52 30L6 62V2z"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                ></path>
              </svg>
            </button>
          )}
        </figure>
        <div className="card-body lg:w-2/5">
          {header ? (
            <h2 className="card-title">{header.props.children}</h2>
          ) : null}
          {body ? body.props.children : null}
          {actions ? (
            <div className="justify-start space-x-2 card-actions">
              {actions.props.children}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default MediaCard;
