import React, { useState } from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
import HeroCard from "../../components/HeroCard";

export const ThankYouInfo = () => {
  return (
    <HeroCard>
      <HeroCard.Header>Helemaal klaar!</HeroCard.Header>
      <HeroCard.Body>
        <p></p>
      </HeroCard.Body>
    </HeroCard>
  );
};
