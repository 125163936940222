import React, { useState } from "react";
import Card from "../../components/Card";
import { VolunteeringSelection } from "./VolunteeringSelection";
import { Link, useHistory } from "react-router-dom";
import { DevMode } from "../../components/DevMode";
import { getActiveRegistration } from "../../store/registration/registrationSelectors";
import { useDispatch, useSelector } from "react-redux";
import FormCard from "../../components/FormCard";
import { bit_clear, bit_set, bit_test, getBit } from "../../utils/BitUtils";
import { VOLUNTEER_TEXTS, VOLUNTEER_TYPE } from "../../store/data-constants";
import { capitalizeFirst } from "../../utils/StringUtils";
import { setRegistrationParameter } from "../../store/registration/registrationSlice";
import { InfoModal } from "../../components/InfoModal";

export const VolunteerForm = () => {
  const registration = useSelector(getActiveRegistration);
  // TODO: Check if edit or new. Let's presume new for now
  const [volunteerChoices, setVolunteerChoices] = useState(
    registration.previousRegistration.volunteer
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCheckboxChange = (e) => {
    setVolunteerChoices((prev) => {
      const bit = getBit(e.target.value);
      if (e.target.checked) return bit_set(prev, bit);
      else return bit_clear(prev, bit);
    });
  };

  const processForm = (e) => {
    e.preventDefault();

    dispatch(
      setRegistrationParameter({
        key: "volunteer",
        value: volunteerChoices,
      })
    );

    // Check if trainer
    const trainerBit = getBit(e.target.value);
    if (bit_test(volunteerChoices, trainerBit)) {
      history.push("/trainer-voordelen");
    } else {
      history.push("/familie-informatie");
    }
  };

  return (
    <>
      <FormCard onSubmit={processForm}>
        <FormCard.Header>Vrijwilligerswerk</FormCard.Header>
        <FormCard.Body>
          <p>Ik help graag bij:</p>
          {Object.entries(VOLUNTEER_TYPE).map(([key, value], i) => {
            return (
              <div className="form-control flex-row items-center" key={value}>
                <div className="flex-grow">
                  <label className="cursor-pointer label justify-start">
                    <input
                      type="checkbox"
                      className="checkbox checkbox-primary mr-4"
                      checked={volunteerChoices & value}
                      value={value}
                      onChange={handleCheckboxChange}
                    />
                    <span className="label-text text-base">
                      {VOLUNTEER_TEXTS[value]}
                    </span>
                  </label>
                </div>
              </div>
            );
          })}
        </FormCard.Body>
        <FormCard.Actions>
          <button className="btn btn-primary" to="#">
            Volgende
          </button>
        </FormCard.Actions>
      </FormCard>
      {/* <DevMode>
        <Link className="btn btn-primary btn-sm" to="/trainer-voordelen">
          Trainer
        </Link>
        <Link className="btn btn-primary btn-sm" to="/familie-informatie">
          Niet trainer
        </Link>
      </DevMode> */}
    </>
  );
};
