import { useState } from "react";
import { useDispatch } from "react-redux";
import uuid from "react-uuid";
import { Alert } from "../../../components/Alert";

import FormCard from "../../../components/FormCard";
import { SpinnerOverlay } from "../../../components/SpinnerOverlay";
import { login } from "../../../store/user/userSlice";
import { loginMode } from "../IdentificationForm";
import { useHistory } from "react-router-dom";

export function Login({ setMode, email }) {
  const [error, setError] = useState(null);
  const [user, setUser] = useState({
    email,
    password: "",
  });

  const [callPending, setCallPending] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const submitLogin = (e) => {
    e.preventDefault();
    setCallPending(true);
    dispatch(login({ email: user.email, password: user.password }))
      .unwrap()
      .then((originalPromiseResult) => {
        if (originalPromiseResult.hasRegistration) {
          history.push("/registratie-inladen");
        } else {
          history.push("/wie-inschrijven");
        }
      })
      .catch((rejectedValue) => {
        setCallPending(false);
        // console.log("COMPONENT - Login failed", rejectedValue);
        switch (rejectedValue.code) {
          case 400:
            setError(rejectedValue.messages);
            break;
          case 404:
            setError(["Gebruiker niet gevonden of foutief wachtwoord"]);
            break;
          default:
            setError(["Onbekende fout"]);
        }
      });
  };
  return (
    <FormCard
      onSubmit={submitLogin}
      enableBack={true}
      backAction={(e) => {
        e.preventDefault();
        setMode(loginMode.START);
      }}
    >
      <FormCard.Header>
        <span>Aanmelden</span>
        <span className="block text-secondary">&gt;&nbsp;gebruiker</span>
      </FormCard.Header>

      <FormCard.Body>
        {error !== null && (
          <Alert type="error" className="mb-4">
            <div>
              {error.map((err) => (
                <span className="block" key={uuid()}>
                  {err}
                </span>
              ))}
            </div>
          </Alert>
        )}

        <div className="form-control">
          <label className="label">
            <span className="label-text">Email</span>
          </label>

          <input
            type="email"
            placeholder="tibeert@vosreinaert.be"
            className="input input-bordered"
            value={user.email}
            onChange={(e) => {
              e.preventDefault();
              setUser((prev) => ({ ...prev, email: e.target.value }));
            }}
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Wachtwoord</span>
          </label>

          <input
            type="password"
            className="input input-bordered"
            value={user.password}
            onChange={(e) => {
              e.preventDefault();
              setUser((prev) => ({ ...prev, password: e.target.value }));
            }}
          />
        </div>
        {callPending && <SpinnerOverlay />}
      </FormCard.Body>
      <FormCard.Actions>
        <button
          className="btn btn-primary px-10"
          type="submit"
          disabled={callPending}
        >
          Inloggen
        </button>
        <div className="flex-grow hidden lg:flex">{/* Spacer */}</div>
        <div className="w-full lg:w-fit text-center lg:text-right">
          <a
            href="https://www.vosreinaert.be/mijn-account/lost-password/"
            target="_blank"
            rel="noreferrer"
            className="btn btn-link  pr-0"
          >
            Wachtwoord vergeten? *
          </a>
        </div>
        <span className="text-xs italic block mt-2">
          * Let op, het kan een aantal minuten duren voordat u de email ontvangt
          voor het herstellen van uw wachtwoord. Controleer zeker ook uw spam
          map.
        </span>
      </FormCard.Actions>
    </FormCard>
  );
}
