import React from "react";
import { Route, Switch } from "react-router-dom";
// import { ConnectedRouter } from "connected-react-router";

import { HomePage } from "../pages/HomePage";
import { IdentificationForm } from "../pages/forms/IdentificationForm";
import { IdentityConfirmationQuestion } from "../pages/questions/IdentityConfirmationQuestion";
import { FirstTimeQuestion } from "../pages/questions/FirstTimeQuestion";
import { FirstTimeMedia } from "../pages/media/FirstTimeMedia";
import { ReturningMedia } from "../pages/media/ReturningMedia";
import { CreateEmailQuestion } from "../pages/questions/CreateEmailQuestion";
import { SubjectIdentificationForm } from "../pages/forms/SubjectIdentificationForm";
import { BirthDateForm } from "../pages/forms/BirthDateForm";
import { BirthDateCheckForm } from "../pages/forms/BirthDateCheckForm";
import { SubjectIdentityConfirmationQuestion } from "../pages/questions/SubjectIdentityConfirmationQuestion";
import { ParentMedia } from "../pages/media/ParentMedia";
import { ParentFirstMessage } from "../pages/ParentFirstMessage";
import { ParentConfirmationQuestion } from "../pages/questions/ParentConfirmationQuestion";
import { ThankYouMedia } from "../pages/media/ThankYouMedia";
import { RegistrationTypeQuestion } from "../pages/questions/RegistrationTypeQuestion";
import { ActiveSporterQuestion } from "../pages/questions/ActiveSporterQuestion";
import { SupporterMermbershipQuestion } from "../pages/questions/SupporterMermbershipQuestion";
import { SupporterMermbershipMedia } from "../pages/media/SupporterMermbershipMedia";
import { SeniorMembershipMedia } from "../pages/media/SeniorMembershipMedia";
import { JuniorMembershipMedia } from "../pages/media/JuniorMembershipMedia";
import { SeniorMembershipForm } from "../pages/forms/SeniorMembershipForm";
import { JuniorMembershipForm } from "../pages/forms/JuniorMembershipForm";
import { VolunteerThanksMedia } from "../pages/media/VolunteerThanksMedia";
import { VolunteerMedia } from "../pages/media/VolunteerMedia";
import { VolunteerForm } from "../pages/forms/VolunteerForm";
import { VolunteerNewMedia } from "../pages/media/VolunteerNewMedia";
import { TrainerDiscountMedia } from "../pages/media/TrainerDiscountMedia";
import { FamilyMedia } from "../pages/media/FamilyMedia";
import { FamilyForm } from "../pages/forms/FamilyForm";
import { RegistrationTargetQuestion } from "../pages/questions/RegistrationTargetQuestion";
import { PersonalDataReviewForm } from "../pages/forms/PersonalDataReviewForm";
import { VolunteerQuestion } from "../pages/questions/VolunteerQuestion";
import { PaymentPage } from "../pages/PaymentPage";
import { FamilyInfo } from "../pages/info/FamilyInfo";
import { JuniorMembershipInfo } from "../pages/info/JuniorMembershipInfo";
import { SeniorMembershipInfo } from "../pages/info/SeniorMembershipInfo";
import { ParentInfo } from "../pages/info/ParentInfo";
import { FirstTimeInfo } from "../pages/info/FirstTimeInfo";
import { ReturningInfo } from "../pages/info/ReturningInfo";
import { SupporterMermbershipInfo } from "../pages/info/SupporterMermbershipInfo";
import { VolunteerThanksInfo } from "../pages/info/VolunteerThanksInfo";
import { VolunteerInfo } from "../pages/info/VolunteerInfo";
import { VolunteerNewInfo } from "../pages/info/VolunteerNewInfo";
import { ThankYouInfo } from "../pages/info/ThankYouInfo";
import { TrainerDiscountInfo } from "../pages/info/TrainerDiscountInfo";
import { ParentInfoBlock } from "../pages/info/ParentInfoBlock";
import { RegistRenewRegistrationQuestion } from "../pages/questions/RenewRegistrationQuestion";
import { HomePageClosed } from "../pages/HomePageClosed";

export const AppRouter = () => {
  // Checker to see if user is actually logged in, otherwise redirect to root

  return (
    // <BrowserRouter basename="/">
    <Switch>
      <Route path="/" exact={true}>
        {/* <HomePageClosed /> */}
        <HomePage />
      </Route>
      <Route path="/aanmelden" exact={true}>
        <IdentificationForm />
      </Route>
      <Route path="/identiteit-bevestigen" exact={true}>
        <IdentityConfirmationQuestion />
      </Route>
      <Route path="/welkom" exact={true}>
        <FirstTimeQuestion />
      </Route>
      <Route path="/eerste-keer-informatie" exact={true}>
        {/* <FirstTimeMedia /> */}
        <FirstTimeInfo />
      </Route>
      <Route path="/welkom-terug" exact={true}>
        {/* <ReturningMedia /> */}
        <ReturningInfo />
      </Route>
      <Route path="/wie-inschrijven" exact={true}>
        <RegistrationTargetQuestion />
      </Route>
      <Route path="/registratie-inladen" exact={true}>
        <RegistRenewRegistrationQuestion />
      </Route>
      <Route path="/email-aanmaken" exact={true}>
        <CreateEmailQuestion />
      </Route>
      <Route path="/aanmelden-derde" exact={true}>
        <SubjectIdentificationForm />
      </Route>
      <Route path="/identiteit-bevestigen-derde" exact={true}>
        <SubjectIdentityConfirmationQuestion />
      </Route>
      <Route path="/geboortejaar-check" exact={true}>
        <BirthDateCheckForm />
      </Route>
      <Route path="/geboortejaar" exact={true}>
        <BirthDateForm />
      </Route>
      <Route path="/ouder-informatie" exact={true}>
        {/* <ParentMedia /> */}
        <ParentInfo />
      </Route>
      <Route path="/ouder-informatie-block" exact={true}>
        {/* <ParentMedia /> */}
        <ParentInfoBlock />
      </Route>
      <Route path="/ouder-eerst" exact={true}>
        <ParentFirstMessage />
      </Route>
      <Route path="/ouderlid-bevestigen" exact={true}>
        <ParentConfirmationQuestion />
      </Route>
      <Route path="/persoonlijke-gegevens" exact={true}>
        <PersonalDataReviewForm />
      </Route>
      <Route path="/type-registratie" exact={true}>
        <RegistrationTypeQuestion />
      </Route>
      <Route path="/actief-sporter" exact={true}>
        <ActiveSporterQuestion />
      </Route>
      <Route path="/steunend-lid-informatie" exact={true}>
        {/* <SupporterMermbershipMedia /> */}
        <SupporterMermbershipInfo />
      </Route>
      <Route path="/steunend-lid-keuze" exact={true}>
        <SupporterMermbershipQuestion />
      </Route>
      <Route path="/senior-lid-informatie" exact={true}>
        {/* <SeniorMembershipMedia /> */}
        <SeniorMembershipInfo />
      </Route>
      <Route path="/junior-lid-informatie" exact={true}>
        {/* <JuniorMembershipMedia /> */}
        <JuniorMembershipInfo />
      </Route>
      <Route path="/senior-lid-registratie" exact={true}>
        <SeniorMembershipForm />
      </Route>
      <Route path="/junior-lid-registratie" exact={true}>
        <JuniorMembershipForm />
      </Route>
      <Route path="/vrijwilliger-bedankt" exact={true}>
        {/* <VolunteerThanksMedia /> */}
        <VolunteerThanksInfo />
      </Route>
      <Route path="/vrijwilliger-informatie" exact={true}>
        {/* <VolunteerMedia /> */}
        <VolunteerInfo />
      </Route>
      <Route path="/vrijwilliger-registratie" exact={true}>
        <VolunteerForm />
      </Route>
      <Route path="/vrijwilliger-worden" exact={true}>
        <VolunteerQuestion />
      </Route>

      <Route path="/vrijwilliger-erbij" exact={true}>
        {/* <VolunteerNewMedia /> */}
        <VolunteerNewInfo />
      </Route>
      <Route path="/trainer-voordelen" exact={true}>
        {/* <TrainerDiscountMedia /> */}
        <TrainerDiscountInfo />
      </Route>
      <Route path="/familie-informatie" exact={true}>
        {/* <FamilyMedia /> */}
        <FamilyInfo />
      </Route>
      <Route path="/familie-registratie" exact={true}>
        <FamilyForm />
      </Route>
      <Route path="/betaling" exact={true}>
        <PaymentPage />
      </Route>
      <Route path="/bedankt" exact={true}>
        {/* <ThankYouMedia /> */}
        <ThankYouInfo />
      </Route>
    </Switch>
    // </BrowserRouter>
  );
};
