import React, { useState } from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
import { DevMode } from "../../components/DevMode";

export const SubjectIdentityConfirmationQuestion = () => {
  return (
    <>
      <Card>
        <Card.Header>Identiteit bevestigen</Card.Header>
        <Card.Body>
          <p>
            We hebben volgend lid gevonden voor dat email adres. <br />
            Is dit correct?
          </p>
          <dl>
            <div className="bg-base-200 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-base-content text-opacity-40">
                Full name
              </dt>
              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                Margot Foster
              </dd>
            </div>
            <div className=" px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-base-content text-opacity-40">
                Application for
              </dt>
              <dd className="mt-1 text-sm  sm:mt-0 sm:col-span-2">
                Backend Developer
              </dd>
            </div>
            <div className="bg-base-200 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-base-content text-opacity-40">
                Email address
              </dt>
              <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                margotfoster@example.com
              </dd>
            </div>
          </dl>
        </Card.Body>
        <Card.Actions>
          <Link className="btn btn-primary" to="#">
            Ja
          </Link>
          <Link className="btn btn-primary" to="/geboortejaar">
            Nee
          </Link>
        </Card.Actions>
      </Card>
      <DevMode>
        <Link className="btn btn-primary btn-sm" to="/ouderlid-bevestigen">
          Jonger &amp; ouderlid gekend
        </Link>
        <Link className="btn btn-primary btn-sm" to="/ouder-informatie">
          Jonger &amp; ouderlid niet gekend
        </Link>
      </DevMode>
    </>
  );
};
