import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import { Alert } from "../../components/Alert";
import { SPORTS } from "../../store/data-constants";
import { bit_clear, bit_set, check_bit, getBit } from "../../utils/BitUtils";
import { capitalizeFirst } from "../../utils/StringUtils";
import FormCard from "../../components/FormCard";
import { useDispatch, useSelector } from "react-redux";
import { getActiveRegistration } from "../../store/registration/registrationSelectors";
import { setRegistrationParameter } from "../../store/registration/registrationSlice";

export const SportSelection = ({ next, edit = false }) => {
  // Check previous registration if we can find parameters
  const registration = useSelector(getActiveRegistration);

  const initialSports =
    registration.parameters.sports > 0
      ? registration.parameters.sports
      : registration.previousRegistration.sports;
  const [sportChoices, setSportChoices] = useState(initialSports);

  console.log(
    registration.parameters.sports,
    registration.previousRegistration.sports
  );

  const initialMain =
    registration.parameters.main_sport > 0
      ? registration.parameters.main_sport
      : registration.previousRegistration.main_sport;
  const [main, setMain] = useState(initialMain);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  const handleCheckboxChange = (e) => {
    if (!e.target.checked && parseInt(e.target.value) === main) {
      setMain(0);
    }

    setSportChoices((prev) => {
      const bit = getBit(e.target.value);
      if (e.target.checked) return bit_set(prev, bit);
      else return bit_clear(prev, bit);
    });
  };

  useEffect(() => {
    if (check_bit(sportChoices)) setMain(sportChoices);
  }, [sportChoices]);

  const handleSwitchChange = (e) => {
    // e.preventDefault();

    setMain((prev) => {
      // if (e.target.checked) {
      if (prev !== parseInt(e.target.value)) return parseInt(e.target.value);
      return 0;

      // return parseInt(e.target.value);
      // } else if (prev === parseInt(e.target.value)) return 0;
    });
  };

  const validateForm = () => {
    if (sportChoices === 0) {
      setError("Geef ten minste 1 sport op");
      return false;
    }

    if (main === 0) {
      setError("Hoofdsport vergeten aanduiden");
      return false;
    }

    return true;
  };

  const processForm = (e) => {
    e.preventDefault();

    if (validateForm()) {
      dispatch(
        setRegistrationParameter({
          key: "sports",
          value: { sports: sportChoices, main_sport: main },
        })
      );
      next();
    }

    // if (isValid) processInput();
  };

  return (
    <FormCard onSubmit={processForm} enableBack={true}>
      <FormCard.Header>Sporttakken</FormCard.Header>
      <FormCard.Body>
        <div className="">
          <p>Selecteer alle sporttakken waar je voor wil inschrijven.</p>
          <p>
            Geef ook aan wat je hoofdsport is. Dit is de sport die je het vaakst
            zal beoefenen.
          </p>

          <div className="flex space-x-4 justify-end pb-2 border-b mb-2">
            <div className="w-3/5 lg:w-4/5  font-semibold">Sportkeuze</div>

            <div className="w-2/5 lg:w-1/5 offset font-semibold text-right text-accent">
              Hoofdsport
            </div>
          </div>
          {Object.entries(SPORTS)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(([key, value], i) => {
              return (
                <div
                  key={value}
                  className=" flex space-x-4 justify-center items-center"
                >
                  <div className="form-control w-4/5">
                    <label className="cursor-pointer label justify-start">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-primary mr-4"
                        checked={sportChoices & value}
                        value={value}
                        onChange={handleCheckboxChange}
                      />
                      <span className="label-text text-base">
                        {capitalizeFirst(key)}
                      </span>
                    </label>
                  </div>
                  <div className="w-1/5 justify-end flex">
                    <input
                      type="checkbox"
                      disabled={!(sportChoices & value)}
                      className="toggle toggle-accent"
                      checked={main === value}
                      readOnly
                      value={value}
                      // onChange={handleSwitchChange}
                      onClick={handleSwitchChange}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </FormCard.Body>
      <FormCard.Actions>
        <button className="btn btn-primary" to="#">
          Volgende
        </button>
        <div className="flex-grow"></div>
        {error && (
          <Alert type="warning" className="w-auto py-3 shadow-none">
            <div>{error}</div>
          </Alert>
        )}
      </FormCard.Actions>
    </FormCard>
  );
};
