import React, { useState } from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
import HeroCard from "../../components/HeroCard";

export const TrainerDiscountInfo = () => {
  return (
    <HeroCard>
      <HeroCard.Header>Uitleg trainerkorting</HeroCard.Header>
      <HeroCard.Body>
        <p>
          Trainers genieten van een gratis lidmaatschap én een item naar keuze
          uit de VOSShop!
          <br />
          Ook mag 1 kind van een trainer 1 jaar gratis trainen!
        </p>
        <p>
          We passen de korting alvast toe voor je inschrijving. <br />
          Gebruik de code <span className="italic underline">trainer</span> bij
          je toekomstige bestellingen in de VOSShop
        </p>
      </HeroCard.Body>
      <HeroCard.Actions>
        <Link className="btn btn-primary" to="/familie-informatie">
          Volgende
        </Link>
      </HeroCard.Actions>
    </HeroCard>
  );
};
