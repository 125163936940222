import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { PersistGate } from "reduxjs-toolkit-persist/integration/react";
import { ReduxCache } from "./components/ReduxCache";
// import { RegistrationHeader } from "./components/RegistrationHeader";
import { AppRouter } from "./routers/AppRouter";
import { store, history, persistor } from "./store";
import { RegistrationOverview } from "./components/RegistrationOverview";

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
        <div className="App pt-4 lg:pt-0 min-h-screen">
          <div className="mx-auto mb-4 lg:mb-4 lg:pt-0 lg:fixed lg:top-4 w-24 h-24 lg:left-1/2 lg:-translate-x-1/2">
            <div className="shadow-wrap">
              <div className="p-4 bg-white  mask mask-squircle">
                <img src="img/vos-logo.svg" className="w-full h-auto" />
              </div>
            </div>
          </div>
          <div className="lg:flex lg:items-center lg:justify-center lg:h-screen lg:min-h-screen px-5">
            {/* bg-base-100*/}
            {/* <RegistrationHeader /> */}
            <AppRouter />
          </div>
        </div>
        {/* <RegistrationOverview /> */}
        {/* {process.env.NODE_ENV !== "production" && (
            <ReduxCache persistor={persistor} />
          )} */}
        {/* </PersistGate> */}
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
