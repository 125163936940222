import React, { useState } from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
import HeroCard from "@components/HeroCard";
import { UseSkip } from "../../hooks/UseSkip";

export const ParentInfo = () => {
  UseSkip("ParentInfo", "/ouderlid-bevestigen");

  return (
    <HeroCard>
      <HeroCard.Header>
        Wist je dat elk lid onder 18j een ouderlid moet hebben?
      </HeroCard.Header>
      <HeroCard.Body>
        <p>
          Dit is gratis en dient het doel om iedereen correct en apart te kunnen
          bereiken die wekelijks op onze club vertoeft.
        </p>
      </HeroCard.Body>
      <HeroCard.Actions>
        <Link className="btn btn-primary" to="/ouderlid-bevestigen">
          OK
        </Link>
      </HeroCard.Actions>
    </HeroCard>
  );
};
