import React from "react";
import Card from "../../components/Card";

import { useDispatch, useSelector } from "react-redux";
import { getActiveRegistration } from "../../store/registration/registrationSelectors";
import { setRegistrationParameter } from "../../store/registration/registrationSlice";
import { MEMBER_TYPE } from "../../store/data-constants";
import { useHistory } from "react-router-dom";
export const SupporterMermbershipQuestion = () => {
  const registration = useSelector(getActiveRegistration);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleNext = () => {
    // Check if volunteer
    // TODO: define if this is edit current or new
    // for the sake of it let's assume new
    if (registration.previousRegistration.volunteer) {
      history.push("/vrijwilliger-bedankt");
    } else {
      history.push("/vrijwilliger-informatie");
    }
  };

  const handleSupportingMember = () => {
    dispatch(
      setRegistrationParameter({ key: "type", value: MEMBER_TYPE.STEUNEND_LID })
    );
    handleNext();
  };

  return (
    <>
      <Card>
        <Card.Header>Wil je inschrijven als steunend lid?</Card.Header>
        <Card.Body>
          <p>Het steunend lidmaatschap bedraagt €15.</p>
          <ul className="list-disc list-inside">
            <li>Je ontvangt maandelijks de nieuwsbrief van VOS Reinaert.</li>
            <li>Je mag altijd petanque komen spelen op recreatieve basis.</li>
            <li>
              Je kan de chalet, keuken, zaal, cabines & pleinen afhuren voor
              kleine familiale events indien beschikbaar.
            </li>
            <li>
              Je bent lid van de vzw en hebt inspraak op de algemene
              vergadering.
            </li>
          </ul>
        </Card.Body>
        <Card.Actions>
          <button className="btn btn-primary" onClick={handleSupportingMember}>
            Ja
          </button>
          <button className="btn btn-primary" onClick={handleNext}>
            Nee
          </button>
        </Card.Actions>
      </Card>
    </>
  );
};
