import React, { useEffect, useState } from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
import HeroCard from "../../components/HeroCard";
import { UseSkip } from "../../hooks/UseSkip";

export const FamilyInfo = () => {
  UseSkip("FamilyInfo", "/familie-registratie");

  return (
    <HeroCard>
      <HeroCard.Header>Extra gezinskorting</HeroCard.Header>
      <HeroCard.Body>
        <p>
          We willen het{" "}
          <strong>familiaal karakter van de club versterken</strong>. Daarom is
          er een extra{" "}
          <strong>korting van 10% op het lidgeld voor gezinnen </strong>die
          samen sporten op VOS.
        </p>
        <p>
          Dit geldt enkel voor gezinnen die met 3 of meer op hetzelfde adres
          domicilie hebben.
        </p>
      </HeroCard.Body>
      <HeroCard.Actions>
        <Link className="btn btn-primary btn-lg" to="/familie-registratie">
          Volgende
        </Link>
      </HeroCard.Actions>
    </HeroCard>
  );
};
