import React, { useState } from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRegistrations } from "../../store/registration/registrationSelectors";
import {
  deleteRegistration,
  setActiveRegistration,
} from "../../store/registration/registrationSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  MEMBER_TYPE,
  SPORTS,
  VOLUNTEER_TYPE,
} from "../../store/data-constants";

import { HiOutlinePlus, HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";

/*
import {
  HiOutlineChevronDoubleRight as Chevron,
  // HiOutlineThumbUp,
} from "react-icons/hi";
*/

function displaySports(input) {
  if (input === 0) return ["Geen"];
  return Object.entries(SPORTS).reduce((result, [key, value]) => {
    if ((input & value) === value)
      result.push(key.toLowerCase().replaceAll("_", " "));
    return result;
  }, []);
}

function displayVolunteer(input) {
  if (input === 0) return ["Nee"];
  return Object.entries(VOLUNTEER_TYPE).reduce((result, [key, value]) => {
    if ((input & value) === value)
      result.push(key.toLowerCase().replaceAll("_", " "));
    return result;
  }, []);
}

function displayRegistrationType(input) {
  console.log(input);
  if (input === 0) return ["onbekend"];
  return Object.entries(MEMBER_TYPE).reduce((result, [key, value]) => {
    if ((input & value) === value)
      result.push(key.toLowerCase().replaceAll("_", " "));
    return result;
  }, []);
}

const showType = (input) => {
  return !input || input.length === 0;
};

export const FamilyForm = () => {
  const registrations = useSelector(getRegistrations);
  const dispatch = useDispatch();
  const history = useHistory();

  const switchActiveRegistration = (id) => {
    dispatch(setActiveRegistration(id));
    history.push("/persoonlijke-gegevens");
  };

  const handleDeleteRegistration = (id) => {
    dispatch(deleteRegistration(id));
  };

  return (
    <>
      <Card>
        <Card.Header>Registratie overzicht</Card.Header>
        <Card.Body>
          <p>
            Klik op de naam van de persoon om de gegevens te tonen en aan te
            passen.
          </p>
          <div className="join join-vertical w-full">
            {registrations.map((r, idx) => (
              <div
                className="collapse collapse-arrow join-item border border-base-300"
                key={r.id}
              >
                <input type="radio" name="family" />
                <div className="collapse-title font-semibold">
                  {r.profile.first_name} {r.profile.last_name}
                </div>
                <div className="collapse-content">
                  <table className="table">
                    <tbody>
                      <tr>
                        <th className="text-left align-top">Adresgegevens:</th>
                        <td>
                          <span className="break-all">{r.profile.address}</span>
                          <br />
                          {r.profile.zip} {r.profile.city}, {r.profile.country}
                        </td>
                      </tr>
                      <tr>
                        <th className="text-left">Geboortedatum:</th>
                        <td>{r.profile.date_of_birth}</td>
                      </tr>
                      {/* {showType(r.parameters.type) && ( */}
                      <tr>
                        <th className="text-left align-top">Type sporter:</th>
                        <td className="grid gap-2">
                          {displayRegistrationType(r.parameters.type).map(
                            (i) => i
                          )}
                        </td>
                      </tr>
                      {/* )} */}
                      <tr>
                        <th className="text-left align-top">Sporten:</th>
                        <td className="grid gap-2">
                          {displaySports(r.parameters.sports).map((sport) => (
                            <span
                              className="badge badge-secondary badge-sm capitalize"
                              key={sport}
                            >
                              {sport}
                            </span>
                          ))}
                        </td>
                      </tr>
                      <tr>
                        <th className="text-left">Hoofdsport:</th>
                        <td>
                          {displaySports(r.parameters.main_sport).map(
                            (sport) => (
                              <span
                                className="badge badge-secondary badge-sm capitalize"
                                key={sport}
                              >
                                {sport}
                              </span>
                            )
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="text-left">Team:</th>
                        <td>{r.parameters.team ?? "nvt"}</td>
                      </tr>
                      <tr>
                        <th className="text-left align-top">Vrijwilliger:</th>
                        <td className="grid gap-2">
                          {displayVolunteer(r.parameters.volunteer).map(
                            (volunteer) => (
                              <span
                                className="badge badge-secondary badge-sm capitalize"
                                key={volunteer}
                              >
                                {volunteer}
                              </span>
                            )
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="flex justify-between">
                    <button
                      className="btn btn-tenary mt-2 flex items-center space-x-2"
                      onClick={() => {
                        switchActiveRegistration(r.id);
                      }}
                    >
                      <HiOutlinePencil className="w-4 h-4" />
                      <span>Aanpassen</span>
                    </button>
                    {idx > 0 && (
                      <button
                        className="btn btn-error mt-2 flex items-center space-x-2"
                        onClick={() => {
                          handleDeleteRegistration(r.id);
                        }}
                      >
                        <HiOutlineTrash className="w-4 h-4" />
                        <span>Verwijderen</span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <Link
            className="btn btn-secondary mt-4 w-full lg:w-fit flex space-x-2 items-center"
            to="/email-aanmaken"
          >
            <HiOutlinePlus className="w-4 h-4" />
            <span>Familielid toevoegen</span>
          </Link>
          <hr className="h-px mt-4 bg-gray-200 border-0 dark:bg-gray-700" />
        </Card.Body>
        <Card.Actions>
          <Link className="btn btn-primary" to="/betaling">
            Registratie afronden / betalen
          </Link>
        </Card.Actions>
      </Card>
      {/* <DevMode>
        <Link className="btn btn-primary btn-sm" to="/bedankt">
          Volgende, Geen betaling nodig
        </Link>
        <Link className="btn btn-primary btn-sm" to="/betaling">
          Volgende, betaling nodig
        </Link>
      </DevMode> */}
    </>
  );
};
