import Card from "../../../components/Card";
import { loginMode } from "../IdentificationForm";

export function Start({ setMode }) {
  return (
    <Card>
      <Card.Header>Welkom bij VOS Reinaert</Card.Header>

      <Card.Body>
        <p className="my-2">
          Heb je al een account bij VOS?
          <br />
          Zo kunnen we alles al proberen invullen voor je.{" "}
        </p>
      </Card.Body>
      <Card.Actions>
        {/* <div className="flex space-x-4 mt-5"> */}
        <button
          onClick={() => {
            setMode(loginMode.LOGIN);
          }}
          className="btn btn-primary w-full lg:w-fit lg:flex-grow"
        >
          Ja
        </button>
        <button
          onClick={() => {
            setMode(loginMode.REGISTREREN);
          }}
          className="btn btn-secondary  w-full lg:w-fit lg:flex-grow"
        >
          Nee
        </button>
        <button
          onClick={() => {
            setMode(loginMode.OPZOEKEN);
          }}
          className="btn btn-outline w-full lg:w-fit lg:flex-grow"
        >
          Ik weet het niet
        </button>
      </Card.Actions>
    </Card>
  );
}
