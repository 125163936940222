import React, { useState } from "react";
import Card from "../../components/Card";
import { Link, useHistory } from "react-router-dom";
import { DevMode } from "../../components/DevMode";
import { useDispatch, useSelector } from "react-redux";
import { underAge } from "../../store/registration/registrationSelectors";
import { setRegistrationParameter } from "../../store/registration/registrationSlice";
import { MEMBER_TYPE } from "../../store/data-constants";
// import { push } from "connected-react-router";

export const ActiveSporterQuestion = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const underage = useSelector(underAge);

  const handleYes = () => {
    dispatch(
      setRegistrationParameter({
        key: "type",
        value: MEMBER_TYPE.ACTIEF_SPORTER,
      })
    );
    dispatch(
      setRegistrationParameter({
        key: "adult",
        value: !underage,
      })
    );
    if (underage) {
      history.push("/junior-lid-informatie");
    } else {
      history.push("/senior-lid-informatie");
    }
  };

  return (
    <>
      <Card enableBack={true}>
        <Card.Header>Actief sporten?</Card.Header>
        <Card.Body>
          Actief sporten is deelnemen aan trainingen en competities binnen een
          of meerdere sporttakken van VOS Reinaert.
          <br />
        </Card.Body>
        <Card.Actions>
          <button className="btn btn-primary px-10" onClick={handleYes}>
            Ja
          </button>
          <Link
            className="btn btn-secondary px-10"
            to="/steunend-lid-informatie"
          >
            Nee
          </Link>
        </Card.Actions>
      </Card>
    </>
  );
};
