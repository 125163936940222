import React from "react";
import { Link } from "react-router-dom";
import Card from "../components/Card";

export const ParentFirstMessage = () => {
  return (
    <Card>
      <Card.Header>Ouderlid onbekend of nog niet ingeschreven</Card.Header>
      <Card.Body>
        Keer terug om uw registratie als ouderlid eerst in orde te brengen
      </Card.Body>
      <Card.Actions>
        <Link className="btn btn-primary" to="/aanmelden">
          Terug
        </Link>
      </Card.Actions>
    </Card>
  );
};
