export const createProfile = (values = null) => {
  const profile = {
    user_ID: 0,
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    zip: "",
    city: "",
    country: "",
    phone: "",
    date_of_birth: "",
    sex: "",
  };
  if (values !== null) Object.assign(profile, values);

  return profile;
};
export const createRegistrationParameters = (values = null) => {
  const parameters = {
    id: 0,
    mode: "", // UPDATE | CREATE;
    year: 2022, // if year < registeringForYear -> registration is not good
    type: 0, // ACTIEF_SPORTER, STEUNEND_LID, OUDERLID
    adult: 0, // 0 or 1
    sports: 0, // bit;
    main_sport: 0, // single bit (1=>128)
    order_item_id: "",
    order_id: "",
    paid: 0, // 0 or 1;
    parent: 0,
    registered_by: 0, // user I;
    volunteer: 0,
    team: null,
  };
  if (values !== null) Object.assign(parameters, values);

  return parameters;
};

export const createRegistration = (id, user_ID) => {
  return {
    id, // uuid
    user_ID,
    is_requester: false, // If this is the person initiating the flow
    is_parent: false,
    profile: createProfile(),
    previousRegistration: {}, // same as parameters
    parameters: createRegistrationParameters(),
  };
};
