import React from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";

export const VolunteerQuestion = () => {
	return (
		<Card>
			<Card.Header>Wil je een handje toesteken op de club?</Card.Header>
			<Card.Body>
				<p>
					We willen graag dat je iets doet waar je zin in hebt en energie van
					krijgt. Geef op de volgende pagina aan waar je eventueel een held in
					bent. We contacteren je vrijblijvend indien nodig.
				</p>
			</Card.Body>
			<Card.Actions>
				<Link className='btn btn-primary' to='/vrijwilliger-registratie'>
					Ja
				</Link>
				<Link className='btn btn-secondary' to='/familie-informatie'>
					Nee
				</Link>
			</Card.Actions>
		</Card>
	);
};
