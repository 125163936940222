import React, { useState } from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
// import { DevMode } from "../../components/DevMode";
import { useDispatch } from "react-redux";
import {
  checkForParentRegistration,
  startRegistration,
} from "../../store/registration/registrationSlice";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SpinnerOverlay } from "../../components/SpinnerOverlay";

// import { push } from "connected-react-router";

export const RegistrationTargetQuestion = () => {
  const dispatch = useDispatch();
  const [callPending, setCallPending] = useState(false);
  const history = useHistory();
  const user = useSelector((state) => state.user);

  const handlePersonal = () => {
    setCallPending(true);
    dispatch(startRegistration({ user_ID: user.user_ID }))
      .unwrap()
      .then((originalPromiseResult) => {
        setCallPending(false);
        history.push("/persoonlijke-gegevens");
      })
      .catch((rejectedValue) => {
        setCallPending(false);
      });
  };

  const handleChild = async () => {
    history.push("/geboortejaar-check");
    // const parentIsRegistered = await dispatch(checkForParentRegistration());
    // if (parentIsRegistered.payload === false) {
    //   history.push("/ouder-eerst");
    // } else {
    // }
  };

  return (
    <>
      <Card>
        <Card.Header>Wil je jezelf inschrijven?</Card.Header>
        <Card.Body>
          <p>
            Elk kind onder de 18 heeft een ouderlid nodig. Gelieve in dat geval
            eerst jezelf eerst in te schrijven.
          </p>
          {callPending && <SpinnerOverlay />}
        </Card.Body>
        <Card.Actions>
          <button
            disabled={callPending}
            className="btn btn-primary px-10"
            onClick={handlePersonal}
          >
            Mezelf
          </button>
          <button
            onClick={handleChild}
            className="btn btn-secondary px-10 w-full lg:w-fit"
          >
            Kind
          </button>
        </Card.Actions>
      </Card>
      {/* <DevMode>
        <Link className="btn btn-primary btn-sm" to="/persoonlijke-gegevens">
          Ja en ouder dan 18
        </Link>
        <Link className="btn btn-primary btn-sm" to="/ouderlid-bevestigen">
          Ja en jonger dan 18 ouderlid gekend
        </Link>
        <Link className="btn btn-primary btn-sm" to="/ouder-informatie">
          Ja en jonger dan 18 ouderlid niet gekend
        </Link>
      </DevMode> */}
    </>
  );
};
