import React, { useRef, useState } from "react";
import FormCard from "../../components/FormCard";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getActiveProfile,
  getRegistrationsCount,
  getParentRegistration,
} from "../../store/registration/registrationSelectors";
import { saveProfile } from "../../store/registration/registrationSlice";
import uuid from "react-uuid";
import { useDispatch } from "react-redux";
import { Alert } from "../../components/Alert";
import { SpinnerOverlay } from "../../components/SpinnerOverlay";
import PhoneInput from "react-phone-number-input/input";

export const PersonalDataReviewForm = () => {
  const activeProfile = useSelector(getActiveProfile);
  const parentRegistration = useSelector(getParentRegistration);
  const [profile, setProfile] = useState(activeProfile);
  const history = useHistory();
  const dispatch = useDispatch();
  const [callPending, setCallPending] = useState(false);
  const [error, setError] = useState(null);
  const formTopRef = useRef();
  const copyFromParent = () => {
    setProfile({
      ...profile,
      address: parentRegistration.profile.address,
      zip: parentRegistration.profile.zip,
      city: parentRegistration.profile.city,
      country: parentRegistration.profile.country,
    });
  };

  const submitForm = (e) => {
    e.preventDefault();

    setCallPending(true);
    // saveProfile();

    dispatch(saveProfile(profile))
      .unwrap()
      .then((originalPromiseResult) => {
        history.push("/type-registratie");
      })
      .catch((rejectedValue) => {
        setCallPending(false);
        // console.log("COMPONENT - Login failed", rejectedValue);
        switch (rejectedValue.code) {
          case 400:
            setError(rejectedValue.messages);
            break;
          case 404:
            setError(["Gebruiker niet gevonden of foutief wachtwoord"]);
            break;
          default:
            setError(["Onbekende fout"]);
        }

        if (formTopRef.current) formTopRef.current.scrollTop = 0;
      });
  };

  return (
    <FormCard onSubmit={submitForm} mainRef={formTopRef} enableBack={true}>
      <FormCard.Header>Persoonlijke gegevens</FormCard.Header>
      <FormCard.Body>
        <p>Gelieve uw gegevens na te kijken en waar nodig aan te passen.</p>

        {parentRegistration && (
          <button
            className="btn btn-secondary px-10"
            onClick={(e) => {
              e.preventDefault();
              copyFromParent();
            }}
          >
            Kopiëren van ouder
          </button>
        )}

        {error !== null && (
          <div className="toast toast-center toast-top">
            <Alert type="error" className="mb-4">
              <div>
                {error.map((err) => (
                  <span className="block" key={uuid()}>
                    {err}
                  </span>
                ))}
              </div>
            </Alert>
          </div>
        )}

        <div className="form-control">
          <label className="label">
            <span className="label-text">Voornaam</span>
          </label>
          <input
            type="text"
            placeholder=""
            required
            className="input input-bordered"
            value={profile.first_name}
            onChange={(e) => {
              e.preventDefault();
              setProfile((prev) => ({
                ...prev,
                first_name: e.target.value,
              }));
            }}
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Achternaam</span>
          </label>
          <input
            type="text"
            placeholder=""
            required
            className="input input-bordered"
            value={profile.last_name}
            onChange={(e) => {
              e.preventDefault();
              setProfile((prev) => ({
                ...prev,
                last_name: e.target.value,
              }));
            }}
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Email</span>
          </label>
          <input
            type="email"
            placeholder=""
            required
            className="input input-bordered"
            value={profile.email}
            disabled={true}
            onChange={(e) => {
              e.preventDefault();
              setProfile((prev) => ({
                ...prev,
                email: e.target.value,
              }));
            }}
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Geboortedatum</span>
          </label>
          <input
            type="date"
            placeholder=""
            required
            className="input input-bordered"
            value={profile.date_of_birth}
            onChange={(e) => {
              e.preventDefault();
              setProfile((prev) => ({
                ...prev,
                date_of_birth: e.target.value,
              }));
            }}
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Adres</span>
          </label>
          <input
            type="text"
            placeholder=""
            required
            className="input input-bordered"
            value={profile.address}
            onChange={(e) => {
              e.preventDefault();
              setProfile((prev) => ({
                ...prev,
                address: e.target.value,
              }));
            }}
          />
        </div>
        <div className="flex space-x-2">
          <div className="form-control w-1/3">
            <label className="label">
              <span className="label-text">Postcode</span>
            </label>
            <input
              type="text"
              placeholder="9100"
              required
              className="input input-bordered"
              value={profile.zip}
              onChange={(e) => {
                e.preventDefault();
                setProfile((prev) => ({
                  ...prev,
                  zip: e.target.value,
                }));
              }}
            />
          </div>
          <div className="form-control w-2/3">
            <label className="label">
              <span className="label-text">Stad</span>
            </label>
            <input
              type="text"
              placeholder="Sint-Niklaas"
              required
              className="input input-bordered"
              value={profile.city}
              onChange={(e) => {
                e.preventDefault();
                setProfile((prev) => ({
                  ...prev,
                  city: e.target.value,
                }));
              }}
            />
          </div>
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Land</span>
          </label>
          <input
            type="text"
            placeholder="België"
            required
            className="input input-bordered"
            value={profile.country ?? ""}
            onChange={(e) => {
              e.preventDefault();
              setProfile((prev) => ({
                ...prev,
                country: e.target.value,
              }));
            }}
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">Telefoon</span>
          </label>
          <PhoneInput
            country="BE"
            placeholder="03 777 12 34 of 0488 45 56 45"
            className="input input-bordered"
            value={profile.phone}
            onChange={(newVvalue) => {
              setProfile((prev) => ({
                ...prev,
                phone: newVvalue,
              }));
            }}
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Geslacht</span>
          </label>
          <select
            className="select select-bordered"
            required
            value={profile.sex ?? ""}
            onChange={(e) => {
              e.preventDefault();
              setProfile((prev) => ({
                ...prev,
                sex: e.target.value,
              }));
            }}
          >
            <option value=""></option>
            <option>M</option>
            <option>V</option>
            <option>X</option>
          </select>
        </div>
        {/*           
          <div className="form-control">
            <label className="label">
              <span className="label-text">Ouder / Voogd (ten laste)</span>
            </label>
            <input
              type="text"
              placeholder=""
              className="input input-bordered"
              value={profile.legalParent}
              onChange={(e) => {
                e.preventDefault();
                setUser((prev) => ({
                  ...prev,
                  profile: { ...prev.profile, legalParent: e.target.value },
                }));
              }}
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Ouder / Voogd</span>
            </label>
            <input
              type="text"
              placeholder=""
              className="input input-bordered"
              value={profile.parent}
              onChange={(e) => {
                e.preventDefault();
                setUser((prev) => ({
                  ...prev,
                  profile: { ...prev.profile, parent: e.target.value },
                }));
              }}
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Broers / Zussen</span>
            </label>
            <input
              type="text"
              placeholder=""
              className="input input-bordered"
              value={profile.family}
              onChange={(e) => {
                e.preventDefault();
                setUser((prev) => ({
                  ...prev,
                  profile: { ...prev.profile, family: e.target.value },
                }));
              }}
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Grootouders</span>
            </label>
            <input
              type="text"
              placeholder=""
              className="input input-bordered"
              value={profile.grandParents}
              onChange={(e) => {
                e.preventDefault();
                setUser((prev) => ({
                  ...prev,
                  profile: { ...prev.profile, grandParents: e.target.value },
                }));
              }}
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Kinderen</span>
            </label>
            <input
              type="text"
              placeholder=""
              className="input input-bordered"
              value={profile.children}
              onChange={(e) => {
                e.preventDefault();
                setUser((prev) => ({
                  ...prev,
                  profile: { ...prev.profile, children: e.target.value },
                }));
              }}
            />
          </div> */}
        {callPending && <SpinnerOverlay />}
      </FormCard.Body>
      <FormCard.Actions>
        <input
          type="submit"
          value="Bevestigen"
          className="btn btn-primary px-10"
          disabled={callPending}
        />
      </FormCard.Actions>
    </FormCard>
  );
};
