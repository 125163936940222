import React, { useState } from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";

export const CreateEmailQuestion = () => {
  return (
    <Card>
      <Card.Header>Heeft deze persoon een email adres?</Card.Header>
      <Card.Body>
        <p>
          Indien deze persoon geen email adres heeft, kunnen wij een
          doorverwijzend email adres aanmaken.
          <br />
          bv. <span className="italic">voornaam.achternaam@vosreinaert.be</span>
        </p>
        <p className="font-bold my-2 alert alert-info">
          Een uniek email adres is vereist voor een registratie
        </p>
      </Card.Body>
      <Card.Actions>
        <Link className="btn btn-primary px-10" to="/aanmelden-derde?email=yes">
          Ja
        </Link>
        <Link
          className="btn btn-secondary px-10"
          to="/aanmelden-derde?email=no"
        >
          Nee, gelieve door te sturen
        </Link>
      </Card.Actions>
    </Card>
  );
};
