import React, { useState } from "react";
import Card from "../../components/Card";
import { SportSelection } from "./SportSelection";
import { Link, useHistory } from "react-router-dom";
// import { DevMode } from "../../components/DevMode";
import { useSelector } from "react-redux";
import { getActiveRegistration } from "../../store/registration/registrationSelectors";
import { TeamSelectionForm } from "./TeamSelectionForm";
import { TEAMS_ADULT } from "../../store/data-constants";

export const SeniorMembershipForm = () => {
  const registration = useSelector(getActiveRegistration);
  const history = useHistory();

  const [mode, setMode] = useState(0);
  const switchModes = (mode = 1) => {
    setMode(mode);
  };
  const handleNext = () => {
    // Check if volunteer
    // TODO: define if this is edit current or new
    // for the sake of it let's assume new
    if (registration.previousRegistration.volunteer) {
      history.push("/vrijwilliger-bedankt");
    } else {
      history.push("/vrijwilliger-informatie");
    }
  };

  return (
    <>
      {mode == 0 && <SportSelection next={switchModes} />}
      {mode == 1 && (
        <TeamSelectionForm
          collection={TEAMS_ADULT}
          back={switchModes}
          next={handleNext}
        />
      )}
      {/* <DevMode>
        <Link className="btn btn-primary btn-sm" to="/vrijwilliger-bedankt">
          Is vrijwilliger
        </Link>
        <Link className="btn btn-primary btn-sm" to="/vrijwilliger-informatie">
          Geen vrijwilliger
        </Link>
      </DevMode> */}
    </>
  );
};
