import React, { Children } from "react";
import { withRouter } from "react-router";
import {
  HiOutlineChevronDoubleLeft as Chevron,
  // HiOutlineThumbUp,
} from "react-icons/hi";

function Header() {
  return null;
}

function Body() {
  return null;
}

function Actions() {
  return null;
}

class HeroCard extends React.Component {
  static Header = Header;
  static Body = Body;
  static Actions = Actions;
  constructor(props) {
    super(props);
    this.state = { playing: false };
    this.videoPlayer = React.createRef();
  }
  playVideo = (e) => {
    e.preventDefault();
    this.videoPlayer.current.play();
    this.setState({ playing: true });
  };
  videoEnded = (e) => {
    this.setState({ playing: false });
  };
  render() {
    const {
      children,
      maxSize = "4xl",
      className = "",
      enableBack = false,
      history,
    } = this.props;
    let { backAction = history.goBack } = this.props;

    const childNodes = Children.toArray(children);

    const header = childNodes.find((child) => child.type === Header);
    const body = childNodes.find((child) => child.type === Body);
    const actions = childNodes.find((child) => child.type === Actions);

    return (
      <div
        className={`card lg:card-side shadow-lg w-full lg:mx-5 md:max-w-${maxSize} ${className} bg-base-100 text-center`}
      >
        <div className="card-body lg:w-2/5">
          {header ? (
            <h1 className="text-4xl font-bold relative">
              {header.props.children}
              {enableBack && (
                <>
                  {/* <span className="flex-grow"></span> */}
                  <button
                    onClick={backAction}
                    className="btn btn-sm opacity-50 hover:opacity-100 btn-outline text-sm absolute right-0 top-1"
                    type="button" // => ignore for form sumbit
                  >
                    <span className="hidden lg:inline-block">Vorige stap</span>
                    <span className="inline-block lg:hidden">
                      <Chevron />
                    </span>
                  </button>
                </>
              )}
            </h1>
          ) : null}
          {body ? (
            <main
              className="overflow-x-hidden overflow-y-auto px-2 relative fancy-scroll
              py-6 lg:mx-10"
            >
              {" "}
              {body.props.children}
            </main>
          ) : null}
          {/* <div className="py-6 mx-10">{body ? body.props.children : null}</div> */}
          {actions ? (
            <div className="justify-center space-x-2 card-actions">
              {actions.props.children}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(HeroCard);
