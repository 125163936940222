export function getBaseLog(x, y) {
  return Math.log(y) / Math.log(x);
}
// Get the bit position based on value
export function getBit(val) {
  return getBaseLog(2, val);
}
// Test if bit is set (pass in bit position)
export function bit_test(num, bit) {
  return (num >> bit) % 2 !== 0;
}

export function bit_set(num, bit) {
  return num | (1 << bit);
}

export function bit_clear(num, bit) {
  return num & ~(1 << bit);
}
// Toggle bit on or off (pass in bit position)
export function bit_toggle(num, bit) {
  return bit_test(num, bit) ? bit_clear(num, bit) : bit_set(num, bit);
}

// Check if only 1 bit is set
export function check_bit(bits) {
  return bits && !(bits & (bits - 1));
}
