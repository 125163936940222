import React from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";

export const FirstTimeQuestion = () => {
  return (
    <Card>
      <Card.Header>Eerste keer op VOS?</Card.Header>
      <Card.Body></Card.Body>
      <Card.Actions>
        <Link className="btn btn-primary" to="/eerste-keer-informatie">
          Ja
        </Link>
        <Link className="btn btn-primary" to="/welkom-terug">
          Nee
        </Link>
      </Card.Actions>
    </Card>
  );
};
