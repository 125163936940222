import React, { useState } from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
// import { DevMode } from "../../components/DevMode";
import { useDispatch } from "react-redux";
import {
  checkForParentRegistration,
  getFullRegistration,
  getPreviousRegistration,
  startRegistration,
} from "../../store/registration/registrationSlice";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SpinnerOverlay } from "../../components/SpinnerOverlay";

// import { push } from "connected-react-router";

export const RegistRenewRegistrationQuestion = () => {
  const dispatch = useDispatch();
  const [callPending, setCallPending] = useState(false);
  const history = useHistory();
  const user = useSelector((state) => state.user);

  const handleYes = () => {
    setCallPending(true);
    dispatch(getFullRegistration({ user_ID: user.user_ID, previous: true }))
      .unwrap()
      .then((originalPromiseResult) => {
        history.push("/familie-registratie");
      })
      .catch((rejectedValue) => {
        setCallPending(false);
        console.log("COMPONENT - fetch failed", rejectedValue);
        // if (rejectedValue.code === 404) {
        //   history.push("/actief-sporter");
        // }
      });
  };

  const handleNo = async () => {
    history.push("/wie-inschrijven");
  };

  return (
    <>
      <Card>
        <Card.Header>Opnieuw inschrijven?</Card.Header>
        <Card.Body>
          <p>
            We hebben een registratie van vorig jaar teruggevonden. Wil je deze
            inladen en gebruiken voor dit jaar?
          </p>
          <p>U kan eerst de gegevens nakijken en waar nodig aanpassen.</p>

          {callPending && <SpinnerOverlay />}
        </Card.Body>
        <Card.Actions>
          <button
            disabled={callPending}
            className="btn btn-primary px-10"
            onClick={handleYes}
          >
            Ja
          </button>
          <button
            onClick={handleNo}
            disabled={callPending}
            className="btn btn-secondary px-10 w-full lg:w-fit"
          >
            Nee / nieuwe registratie
          </button>
        </Card.Actions>
      </Card>
      {/* <DevMode>
        <Link className="btn btn-primary btn-sm" to="/persoonlijke-gegevens">
          Ja en ouder dan 18
        </Link>
        <Link className="btn btn-primary btn-sm" to="/ouderlid-bevestigen">
          Ja en jonger dan 18 ouderlid gekend
        </Link>
        <Link className="btn btn-primary btn-sm" to="/ouder-informatie">
          Ja en jonger dan 18 ouderlid niet gekend
        </Link>
      </DevMode> */}
    </>
  );
};
