import React, { useState } from "react";
import Card from "../../components/Card";
import { Link, useHistory } from "react-router-dom";
// import { Chevron } from "../../components/icons/Chevron";
import {
  HiOutlineChevronDoubleRight as Chevron,
  // HiOutlineThumbUp,
} from "react-icons/hi";
import { useDispatch } from "react-redux";
import { getPreviousRegistration } from "../../store/registration/registrationSlice";
// import { useSelector } from "react-redux";
// import { userState } from "../../store/user/userSelectors";

const config = require("@root/config.json");

export const RegistrationTypeQuestion = () => {
  const [callPending, setCallPending] = useState(false);
  const dispatch = useDispatch();

  const history = useHistory();
  // const user = useSelector(userState);

  const handleNewRegistration = () => {
    setCallPending(true);

    dispatch(getPreviousRegistration())
      .unwrap()
      .then((originalPromiseResult) => {
        history.push("/actief-sporter");
        // console.log("Got it", originalPromiseResult);
      })
      .catch((rejectedValue) => {
        setCallPending(false);
        // console.log("COMPONENT - Login failed", rejectedValue);
        if (rejectedValue.code === 404) {
          history.push("/actief-sporter");
        }
      });
  };
  const handleEditRegistration = () => {};
  const handleSubscribeEvent = () => {};

  const handleHistory = () => {};

  return (
    <Card maxSize="4xl" maxHeight="50vh" enableBack={true}>
      <Card.Header>Wat wil je doen?</Card.Header>
      <Card.Body>
        <div className="grid lg:grid-cols-2 gap-4">
          <div className="card bg-primary text-primary-content">
            <div></div>
            <div className="card-body  text-center ">
              <h2 className="card-title justify-center">
                Inschrijven voor '{config.year.toString().substring(2)}-'
                {parseInt(config.year.toString().substring(2)) + 1}
              </h2>
              <p>Lidmaatschap verlengen voor nog een jaartje</p>
              <div className="card-actions justify-center">
                <button
                  disabled={callPending}
                  className="btn btn-primary px-10"
                  onClick={handleNewRegistration}
                >
                  Selecteren
                  <Chevron className="ml-2" />
                </button>
              </div>
            </div>
          </div>
          <div className="card bg-secondary text-secondary-content">
            <div className="card-body   text-center">
              <h2 className="card-title justify-center">
                Inschrijving '{config.year.toString().substring(2)} aanpassen
              </h2>
              <p>Pas een actief lidmaatschap aan.</p>
              <div className="card-actions justify-center">
                <Link className="btn btn-secondary px-10" to="#">
                  Selecteren
                  <Chevron className="ml-2" />
                </Link>
              </div>
            </div>
            <div className="card absolute bg-secondary bg-opacity-80 w-full h-full top-0 left-0 flex items-center justify-center font-semibold text-center">
              In ontwikkeling.
              <br />
              Kom later nog eens terug.
            </div>
          </div>
          {/* <div className="card bg-accent text-accent-content relative">
            <div className="card-body   text-center">
              <h2 className="card-title justify-center">Tenniskampen '23</h2>
              <p>Schrijf in voor een eenmalig tenniskamp</p>
              <div className="card-actions justify-center">
                <Link className="btn btn-accent px-10" to="#">
                  Selecteren
                  <Chevron className="ml-2" />
                </Link>
              </div>
            </div>
            <div className="card absolute bg-accent bg-opacity-80 w-full h-full top-0 left-0 flex items-center justify-center font-semibold text-center">
              In ontwikkeling.
              <br />
              Kom later nog eens terug.
            </div>
          </div> */}
          {/* <div className="card bg-neutral text-neutral-content relative">
            <div className="card-body  text-center">
              <h2 className="card-title justify-center">Historiek</h2>
              <p>Raadpleeg vorige registraties</p>
              <div className="card-actions justify-center">
                <Link className="btn btn-neutral px-10" to="#">
                  Selecteren
                  <Chevron className="ml-2" />
                </Link>
              </div>
            </div>
            <div className="card absolute bg-neutral text-neutral-content bg-opacity-80 w-full h-full top-0 left-0 flex items-center justify-center font-semibold text-center">
              In ontwikkeling.
              <br />
              Kom later nog eens terug.
            </div>
          </div> */}
        </div>
      </Card.Body>
    </Card>
  );
};
