import React, { useState } from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
import { DevMode } from "../../components/DevMode";

export const ParentConfirmationQuestion = () => {
  return (
    <>
      <Card>
        <Card.Header>Ouderlid registratie</Card.Header>
        <Card.Body>
          <p>
            We hebben u aangeduid als het ouderlid. <br />
            Indien u dit wenst te wijzigen, gelieve aan te melden als het nieuwe
            ouderlid en de registratie voor uw kind te vervolledigen.
          </p>
        </Card.Body>
        <Card.Actions>
          <Link className="btn btn-primary" to="/persoonlijke-gegevens">
            Ok
          </Link>
          {/* <Link className="btn btn-secondary" to="#">
            Iemand anders
          </Link> */}
        </Card.Actions>
      </Card>
      {/* <DevMode>
        <Link className="btn btn-primary btn-sm" to="/ouder-eerst">
          Ouderlid niet ingeschreven
        </Link>
        <Link className="btn btn-primary btn-sm" to="/persoonlijke-gegevens">
          Ouderlid ingeschreven
        </Link>
      </DevMode> */}
    </>
  );
};
