import React from "react";
import { Success } from "@icons/Success";
import { Error } from "@icons/Error";
import { Info } from "@icons/Info";
import { Warning } from "@icons/Warning";

export const Alert = ({ type, className = "", children }) => {
  return (
    <div className={`alert alert-${type} shadow-lg ${className}`}>
      <div className="flex space-x-2">
        {type === "success" && <Success className="flex-shrink-0 h-6 w-6" />}
        {type === "error" && <Error className="flex-shrink-0 h-6 w-6" />}
        {type === "warning" && <Warning className="flex-shrink-0 h-6 w-6" />}
        {type === "info" && <Info className="flex-shrink-0 h-6 w-6" />}
        {children}
      </div>
    </div>
  );
};
