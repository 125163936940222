import React, { useState } from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
import HeroCard from "@components/HeroCard";
import { UseSkip } from "../../hooks/UseSkip";
const config = require("@root/config.json");

export const JuniorMembershipInfo = () => {
  UseSkip("JuniorMembershipInfo", "/junior-lid-registratie");

  return (
    <HeroCard>
      <HeroCard.Header>Lidmaatschap Junior</HeroCard.Header>
      <HeroCard.Body>
        <p>Het junior lidmaatschap bedraagt €{config.junior}</p>
        <p>
          Lid zijn bij VOS geeft de mogelijkheid om met 1 lidgeld deel te nemen
          aan meerdere sporten. Je geeft 1 hoofdsport aan en vervolledigt met
          extra sporten indien nodig.
        </p>
        <p>
          PETANQUE als hoofdsport bedraagt €{config.petanque}, met geen
          mogelijkheid tot extra sporten.
        </p>
      </HeroCard.Body>
      <HeroCard.Actions>
        <Link className="btn btn-primary" to="/junior-lid-registratie">
          Volgende
        </Link>
      </HeroCard.Actions>
    </HeroCard>
  );
};
