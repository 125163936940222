import { createSelector } from "@reduxjs/toolkit";
import { yearsApart } from "../../utils/DateUtils";
import registrationSlice from "./registrationSlice";

const registrationState = (state) => {
  return state.registration;
};

export const getActiveRegistration = createSelector(
  [registrationState],
  (state) => {
    return state.registrations.find(
      (reg) => reg.id === state.activeRegistration
    );
  }
);

export const getRegistrationsCount = createSelector(
  [registrationState],
  (state) => state.registrations.length
);
export const getParentRegistration = createSelector(
  [registrationState],
  (state) =>
    state.registrations.find(
      (reg) => reg.is_parent && reg.id !== state.activeRegistration
    )
);

export const getActiveProfile = createSelector(
  [getActiveRegistration],
  (registration) => registration?.profile
);

export const underAge = createSelector([getActiveProfile], (profile) => {
  const date_of_birth = new Date(profile.date_of_birth);
  const today = new Date();
  return yearsApart(today, date_of_birth) < 18;
});

export const getRegistrations = createSelector([registrationState], (state) => {
  return state.registrations;
});
