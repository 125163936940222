import { useState } from "react";
import { useDispatch } from "react-redux";

import { loginMode } from "../IdentificationForm";
import { lookupUserBy } from "../../../store/user/userSlice";
import { Alert } from "../../../components/Alert";
import uuid from "react-uuid";
import FormCard from "../../../components/FormCard";
import { SpinnerOverlay } from "../../../components/SpinnerOverlay";

export function Lookup({ setModeEmail }) {
  const [messages, setMessages] = useState(null);

  const [found, setFound] = useState(null);
  const [email, setEmail] = useState("");
  const [callPending, setCallPending] = useState(false);

  const dispatch = useDispatch();
  const doLogin = (e) => {
    e.preventDefault();
    setModeEmail(loginMode.LOGIN, email);
  };
  const doLookup = (e) => {
    e.preventDefault();
    setFound(null);
    setMessages(null);
    setCallPending(true);
    dispatch(lookupUserBy({ fieldName: "email", value: email }))
      .unwrap()
      .then((originalPromiseResult) => {
        setMessages(["We hebben een account gevonden met dit email adres"]);
        setFound(true);
        setCallPending(false);
      })
      .catch((rejectedValue) => {
        setCallPending(false);
        switch (rejectedValue.code) {
          case 400:
            setFound(null);
            setMessages(rejectedValue.messages);
            break;
          case 404:
            setFound(false);
            setMessages([
              "We hebben geen account gevonden met dit email adres",
            ]);
            break;
          default:
            setFound(null);
            setMessages(["Onbekende fout"]);
        }
      });
  };

  return (
    <FormCard
      enableBack={true}
      backAction={() => {
        setModeEmail(loginMode.START, "");
      }}
      onSubmit={found === true ? doLogin : doLookup}
    >
      <FormCard.Header>
        <span>Aanmelden</span>
        <span className="block text-secondary">&gt;&nbsp;opzoeken</span>
      </FormCard.Header>

      <FormCard.Body>
        {messages !== null && (
          <Alert type={found ? "success" : "error"} className="mb-4">
            <div>
              {messages.map((err) => (
                <span className="block" key={uuid()}>
                  {err}
                </span>
              ))}
            </div>
          </Alert>
        )}

        {found !== true && (
          <>
            <p>Met welk email adres denkt u geregistreerd te zijn?</p>

            <div className="form-control">
              <label className="label">
                <span className="label-text">Email</span>
              </label>

              <input
                type="email"
                placeholder="tybeert@vosreinaert.be"
                className="input input-bordered"
                value={email}
                onChange={(e) => {
                  e.preventDefault();
                  setEmail((prev) => e.target.value);
                }}
              />
            </div>
          </>
        )}
        {callPending && <SpinnerOverlay />}
      </FormCard.Body>

      <FormCard.Actions>
        <>
          {found !== true && (
            <button
              type="submit"
              className="btn btn-primary px-10"
              disabled={callPending}
            >
              Zoeken
            </button>
          )}

          {found === false && (
            <button
              onClick={() => {
                setModeEmail(loginMode.REGISTREREN, email);
              }}
              className="btn btn-secondary px-10"
            >
              Registreren
            </button>
          )}

          {found === true && (
            <>
              <button type="submit" className="btn btn-primary">
                Klik om verder te gaan
              </button>
              <a
                href="https://www.vosreinaert.be/mijn-account/lost-password/"
                target="_blank"
                rel="noreferrer"
                className="btn btn-link"
              >
                Wachtwoord vergeten? *
              </a>
              <div
                style={{
                  flexBasis: "100%",
                  height: 0,
                }}
              ></div>
              <span className="text-xs italic block mt-2">
                * Let op, het kan een aantal minuten duren voordat u de email
                ontvangt voor het herstellen van uw wachtwoord. Controleer zeker
                ook uw spam map.
              </span>
            </>
          )}
        </>
      </FormCard.Actions>
    </FormCard>
  );
}
