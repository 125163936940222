import React, { useState } from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
import HeroCard from "../../components/HeroCard";
import { UseSkip } from "../../hooks/UseSkip";

export const VolunteerThanksInfo = () => {
  UseSkip("VolunteerThanksInfo", "/vrijwilliger-registratie");

  return (
    <HeroCard>
      <HeroCard.Header>Merci vrijwilliger!</HeroCard.Header>
      <HeroCard.Body>
        <p>
          Bedankt voor je inzet het voorbij jaar.
          <br />
          We hopen ook dit jaar op je inzet te mogen rekenen
          <br />
        </p>
      </HeroCard.Body>
      <HeroCard.Actions>
        <Link className="btn btn-primary" to="/vrijwilliger-registratie">
          Volgende
        </Link>
      </HeroCard.Actions>
    </HeroCard>
  );
};
