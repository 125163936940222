import React, { useState } from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
import HeroCard from "@components/HeroCard";

export const ReturningInfo = () => {
  return (
    <HeroCard>
      <HeroCard.Header>Welkom back op VOS!</HeroCard.Header>
      <HeroCard.Body>
        <p></p>
      </HeroCard.Body>
      <HeroCard.Actions>
        <Link className="btn btn-primary" to="/geboortejaar">
          OK
        </Link>
      </HeroCard.Actions>
    </HeroCard>
  );
};
