import React, { useState } from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
import HeroCard from "../../components/HeroCard";
import { UseSkip } from "../../hooks/UseSkip";

export const VolunteerInfo = () => {
  UseSkip("VolunteerInfo", "/vrijwilliger-worden");

  return (
    <HeroCard>
      <HeroCard.Header>Help je mee?</HeroCard.Header>
      <HeroCard.Body>
        <p>
          Aangezien VOS een club voor en door de leden is, vragen we iedereen
          ook hun steentje bij te dragen aan de clubwerking. <br />
          Welk steentje mag je zelf kiezen, alle talenten of initiatieven
          welkom.
        </p>
        <p>
          Weet dat elke jeugdploeg op zijn minst een trainer & begeleider nodig
          heeft. Tappen tijdens trainingen, mee een terras ineen timmeren of
          samen goals verzetten en de trainers helpen. Het hoort er allemaal bij
        </p>
      </HeroCard.Body>
      <HeroCard.Actions>
        <Link className="btn btn-primary" to="/vrijwilliger-worden">
          Volgende
        </Link>
      </HeroCard.Actions>
    </HeroCard>
  );
};
