import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
  name: "order",
  initialState: {
    order_id: "",
    order_entries: [
      {
        order_entry_id: "",
        registration_id: "",
        description: "",
      },
    ],
  },
  reducers: {},
});

export default orderSlice.reducer;
