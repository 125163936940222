import React, { useState } from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
import HeroCard from "@components/HeroCard";
import { UseSkip } from "../../hooks/UseSkip";
const config = require("@root/config.json");

export const SeniorMembershipInfo = () => {
  UseSkip("SeniorMembershipInfo", "/senior-lid-registratie");

  return (
    <HeroCard enableBack={true}>
      <HeroCard.Header>Lidmaatschap Senior</HeroCard.Header>
      <HeroCard.Body>
        <p>Het senior lidmaatschap bedraagt €{config.senior}</p>
        <p>
          Lid zijn bij VOS geeft de mogelijkheid om met 1 lidgeld deel te nemen
          aan meerdere sporten. Je geeft 1 hoofdsport aan en vervolledigt met
          extra sporten indien gewenst.
        </p>
        <p>
          PETANQUE als hoofdsport bedraagt €{config.petanque}, met geen
          mogelijkheid tot extra sporten.
        </p>
      </HeroCard.Body>
      <HeroCard.Actions>
        <Link className="btn btn-primary" to="/senior-lid-registratie">
          Volgende
        </Link>
      </HeroCard.Actions>
    </HeroCard>
  );
};
