import React, { Children } from "react";
import { withRouter } from "react-router";
import {
  HiOutlineChevronDoubleLeft as Chevron,
  // HiOutlineThumbUp,
} from "react-icons/hi";

function Header() {
  return null;
}

function Body() {
  return null;
}

function Actions() {
  return null;
}

class Card extends React.Component {
  static Header = Header;
  static Body = Body;
  static Actions = Actions;

  render() {
    const {
      children,
      maxSize = "xl",
      className = "",
      maxHeight = "40vh",
      history,
      enableBack = false,
    } = this.props;

    let { backAction = history.goBack } = this.props;

    const childNodes = Children.toArray(children);

    const header = childNodes.find((child) => child.type === Header);
    const body = childNodes.find((child) => child.type === Body);
    const actions = childNodes.find((child) => child.type === Actions);

    return (
      <div
        className={`card card-compact md:card-normal shadow-lg w-full lg:mx-5 md:max-w-${maxSize} ${className} bg-base-100 bg-opacity-100`}
      >
        <div className="card-body">
          {header ? (
            <h2 className="card-title px-2 flex items-baseline">
              {header.props.children}
              {enableBack && (
                <>
                  <span className="flex-grow"></span>
                  <button
                    onClick={backAction}
                    className="btn btn-sm opacity-50 hover:opacity-100 btn-outline text-sm"
                    type="button" // => ignore for form sumbit
                  >
                    <span className="hidden lg:inline-block">Vorige stap</span>
                    <span className="inline-block lg:hidden">
                      <Chevron />
                    </span>
                  </button>
                </>
              )}
            </h2>
          ) : null}
          {body ? (
            <main
              className="overflow-x-hidden overflow-y-auto px-2 relative"
              // style={{
              //   maxHeight: maxHeight,
              // }}
            >
              {" "}
              {body.props.children}
            </main>
          ) : null}
          {actions ? (
            <div className="mt-4 justify-start card-actions flex-col lg:flex-row lg:flex lg:space-x-2 px-2">
              {actions.props.children}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(Card);
