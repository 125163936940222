import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import Card from "../../components/Card";
import { DevMode } from "../../components/DevMode";
import { getActiveProfile } from "../../store/registration/registrationSelectors";
import { setLoggedInUserAsParent } from "../../store/registration/registrationSlice";

export const BirthDateCheckForm = () => {
  const [birthYear, setBirthYear] = useState(new Date().getFullYear());

  const history = useHistory();
  const thisYear = new Date().getFullYear();

  const checkBirthYear = (e) => {
    e.preventDefault();
    if (thisYear - parseInt(birthYear) < 18) {
      history.push("/ouder-informatie-block");
    } else history.push("/email-aanmaken");
  };

  return (
    <>
      <Card>
        <Card.Header>Geboortejaar</Card.Header>
        <Card.Body>
          <p className="mb-4">
            Om verder te gaan zouden we het geboortejaar moeten weten.
          </p>
          <div className="form-control">
            <div className="form-control">
              <div className="">
                <input
                  type="text"
                  className="input input-bordered input-lg"
                  value={birthYear}
                  onChange={(e) => {
                    e.preventDefault();
                    setBirthYear(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </Card.Body>
        <Card.Actions>
          <button className="btn btn-primary" onClick={checkBirthYear}>
            Volgende
          </button>
        </Card.Actions>
      </Card>
    </>
  );
};
