import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import { composeWithDevTools } from "redux-devtools-extension";
import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";

import storageSession from "reduxjs-toolkit-persist/lib/storage";
import {
  persistStore,
  persistCombineReducers,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "reduxjs-toolkit-persist";

// INCLUDE STATE PARTIALS HERE
import order from "./order/orderSlice";
import registration from "./registration/registrationSlice";
import user from "./user/userSlice";

// https://github.com/supasate/connected-react-router/blob/master/FAQ.md#how-to-set-router-props-eg-basename-initialentries-etc
export const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASE_URL,
});

// Make storage persistant for this session
const persistConfig = {
  key: "root",
  storage: storageSession,
  blacklist: ["router"],
  // stateReconciler: autoMergeLevel2, // Automatically with persistCombineReducers
};

// const rootReducer = persistCombineReducers(persistConfig, {
//   order,
//   registration,
//   user,
//   router: connectRouter(history),
// });

const rootReducer = combineReducers({
  order,
  registration,
  user,
  router: connectRouter(history),
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        /* ignore persistance actions */
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(routerMiddleware(history)), // .concat(logger)
  devTools: process.env.NODE_ENV !== "production",
});

// export const persistor = persistStore(store);
