import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { apiPath } from "../utils";
import wretch from "wretch";

const config = require("@root/config.json");

const initialState = {
  loggedIn: false,

  email: "",
  user_ID: 0,

  loading: false,

  lastCallResult: null,
  // {
  //   success: true,
  //   messages: []
  // }
};

export const lookupUserBy = createAsyncThunk(
  "user/lookupUserBy",
  ({ fieldName, value }, { fulfillWithValue, rejectWithValue }) => {
    return wretch(apiPath("user/lookup"))
      .post({ fieldName, value })
      .notFound((error) => rejectWithValue({ code: 404 }))
      .error(400, (error) =>
        rejectWithValue({ code: 400, ...JSON.parse(error.message) })
      )
      .json((response) => fulfillWithValue(response))
      .catch((error) => rejectWithValue({ code: 500 }));
  }
);

export const login = createAsyncThunk(
  "user/login",
  ({ email, password }, { fulfillWithValue, rejectWithValue }) => {
    return wretch(apiPath("user/login"))
      .post({ email, password, year: config.year })
      .notFound((error) => rejectWithValue({ code: 404 }))
      .error(400, (error) => {
        // console.log(JSON.parse(error.message));
        return rejectWithValue({ code: 400, ...JSON.parse(error.message) });
      })
      .json((response) => fulfillWithValue({ email, ...response }))
      .catch((error) => rejectWithValue({ code: 500 }));
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    fakeLogin: (state, action) => {},
    clearLastCall: (state, action) => {},
    register: (state, action) => {},
  },
  /*
  extraReducers: {
    // handle async actions: pending, fulfilled, rejected (for errors)
    [fetchLine.pending](state) {
  */
  extraReducers: (builder) => {
    // lookupUserBy.pending / rejected

    // rejected: state.error = action.error.message
    builder
      .addCase(lookupUserBy.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(lookupUserBy.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(lookupUserBy.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loggedIn = true;
        state.user_ID = action.payload.user_ID;
        state.email = action.payload.email;
      })
      .addCase(login.rejected, (state) => {
        // Login failed.
      });
  },
});

export const { fakeLogin, clearLastCall } = userSlice.actions;

export default userSlice.reducer;
