import React, { useState } from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";

export const IdentityConfirmationQuestion = () => {
  return (
    <Card>
      <Card.Header>Account gevonden</Card.Header>
      <Card.Body>
        <p>Hoera! We hebben een account teruggevonden voor u</p>

        <dl>
          <div className="bg-base-200 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-base-content text-opacity-40">
              Volledige naam
            </dt>
            <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
              Margot Foster
            </dd>
          </div>
          <div className=" px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-base-content text-opacity-40">
              Email adres
            </dt>
            <dd className="mt-1 text-sm  sm:mt-0 sm:col-span-2">
              Backend Developer
            </dd>
          </div>
        </dl>
      </Card.Body>
      <Card.Actions>
        <Link className="btn btn-primary" to="/aanmelden">
          Aanmelden
        </Link>
        <Link className="btn btn-primary" to="/">
          Wachtwoord vergeten
        </Link>
        <div
          style={{
            flexBasis: "100%",
            height: 0,
          }}
        ></div>
        <span className="text-xs italic block mt-2">
          * Let op, het kan een aantal minuten duren voordat u de email ontvangt
          voor het herstellen van uw wachtwoord. Controleer zeker ook uw spam
          map.
        </span>
      </Card.Actions>
    </Card>
  );
};
