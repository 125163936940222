export const MEMBER_TYPE = {
  ACTIEF_SPORTER: 1,
  STEUNEND_LID: 2,
  OUDERLID: 4,
};

export const SPORTS = {
  VOETBAL: 1,
  VOLLEYBAL: 2,
  //KORFBAL: 4,
  TENNIS: 8,
  FRISBEE: 16,
  PETANQUE: 32,
  WIELERVRIENDEN: 64,
};

export const VOLUNTEER_TYPE = {
  TRAINER: 1,
  SCHEIDSRECHTER: 2,
  VOSCREW: 4,
  UITBATING: 8,
  KLUS_EN_INFRASTRUCTUUR: 16,
  FEEST_ONDERSTEUNING: 32,
  COMMUNICATIE: 64,
  BESTUUR: 128,
  DIGITAAL: 256,
};

export const TEAMS_YOUTH = {
  U6: "U6",
  U7: "U7",
  U8: "U8",
  U9: "U9",
  U10: "U10",
  U11: "U11",
  U12: "U12",
  U13: "U13",
  U15: "U15",
  U17: "U17",
  U21: "U21",
};
export const TEAMS_ADULT = {
  U21: "U21",
  R2: "R2",
  R1: "R1",
  Veteranen: "Veteranen",
  VSOP: "VSOP",
};

export const VOLUNTEER_TEXTS = {
  [VOLUNTEER_TYPE.TRAINER]: "training geven en/of ploegen begeleiden",
  [VOLUNTEER_TYPE.SCHEIDSRECHTER]:
    "matches in goede banen leiden als scheidsrechter",
  [VOLUNTEER_TYPE.VOSCREW]:
    "het gezellig maken samen met andere ouders tijdens trainingen",
  [VOLUNTEER_TYPE.UITBATING]: "de bar van VOS meer en beter open te houden",
  [VOLUNTEER_TYPE.KLUS_EN_INFRASTRUCTUUR]:
    "allerhande klusjes door de handen uit de mouwen te steken",
  [VOLUNTEER_TYPE.FEEST_ONDERSTEUNING]:
    "grote feesten & evenementen door mee te helpen",
  [VOLUNTEER_TYPE.DIGITAAL]:
    "ik heb kennis van digitale systemen (website, programmatie) en zou dit voor vos mee willen uitbouwen",
  [VOLUNTEER_TYPE.COMMUNICATIE]:
    "fijne verhalen schrijven & media maken voor communicatie",
  [VOLUNTEER_TYPE.BESTUUR]: "het bestuur van de club, comitee of sporttak",
};

export const RELATIONSHIP = {
  PARENT: "PARENT",
  CHILD: "CHILD",
  PARTNER: "PARTNER",
  ANDER: "ANDER",
};
