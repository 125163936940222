import React, { useState } from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";

import HeroCard from "../../components/HeroCard";

export const FirstTimeInfo = () => {
  return (
    <HeroCard>
      <HeroCard.Header>Welkom op VOS!</HeroCard.Header>
      <HeroCard.Body>
        <p>
          VOS Reinaert is de grootste, uniekste en fijnste omnisport vereniging
          van het Waasland waar je levenslang kan sporten
        </p>
        <p>We wensen je alvast welkom!</p>
      </HeroCard.Body>
      <HeroCard.Actions>
        <Link className="btn btn-primary" to="/geboortejaar">
          OK
        </Link>
      </HeroCard.Actions>
    </HeroCard>
  );
};
