import React, { useState } from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
import HeroCard from "@components/HeroCard";
import { DevMode } from "@components/DevMode";

export const SupporterMermbershipInfo = () => {
  return (
    <>
      <HeroCard video="video/mixkit-promotion-for-social-networks-with-a-smiling-girl-42283.mp4">
        <HeroCard.Header>Steunend lid</HeroCard.Header>
        <HeroCard.Body>
          <p>
            Als je actieve sportcarrière op zijn eind loopt, of gewoon immense
            sympathie voor de club hebt of enkel wat petanque wilt spelen kan je
            ook nog STEUNEND LID worden. Op deze manier blijf je lid van de club
            ook al ben je niet meer actief of kom je niet meer zo vaak. Zo blijf
            je wel op de hoogte van alle activiteiten en behoud je alle
            voordelen van VOS lid te zijn behalve het actief sporten.
          </p>
        </HeroCard.Body>
        <HeroCard.Actions>
          <Link className="btn btn-primary" to="/steunend-lid-keuze">
            Volgende
          </Link>
        </HeroCard.Actions>
      </HeroCard>
    </>
  );
};
