import React, { useState } from "react";
import { HiChevronDoubleRight } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Card from "../components/Card";
import { DevMode } from "../components/DevMode";
import { createOrder } from "../store/registration/registrationSlice";
import { SpinnerOverlay } from "../components/SpinnerOverlay";
import uuid from "react-uuid";
import { Alert } from "../components/Alert";
import { wpPath } from "../store/utils";
import { useEffect } from "react";

export const PaymentPage = () => {
  const [callPending, setCallPending] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [paymentLink, setPaymentLink] = useState("");

  useEffect(() => {
    setCallPending(true);
    dispatch(createOrder())
      .unwrap()
      .then((originalPromiseResult) => {
        setCallPending(false);
        setPaymentLink(
          wpPath(`winkelmand/?r=${encodeURIComponent(originalPromiseResult)}`)
        );
      })
      .catch((rejectedValue) => {
        setCallPending(false);
        // console.log("COMPONENT - Login failed", rejectedValue);
        switch (rejectedValue.code) {
          default:
            setError(["Onbekende fout"]);
        }
      });
  }, []);

  return (
    <>
      <Card>
        <Card.Header>Betaling</Card.Header>
        <Card.Body>
          <p>
            Je wordt nu doorgestuurd naar de webshop van VOS Reinaert om de
            lidgelden af te rekenen. Je kan ineens alle kledij bestellen die je
            nodig hebt voor het nieuwe seizoen.
          </p>
          <p className="alert alert-warning font-semibold">
            Uw registratie is pas definitief na betaling van het lidgeld.
          </p>
          {error !== null && (
            <Alert type="error" className="mb-4">
              <div>
                {error.map((err) => (
                  <span className="block" key={uuid()}>
                    {err}
                  </span>
                ))}
              </div>
            </Alert>
          )}
          {callPending && <SpinnerOverlay />}
        </Card.Body>
        <Card.Actions>
          {!callPending && (
            <a href={paymentLink} className="btn btn-primary">
              Naar betaling
            </a>
          )}
        </Card.Actions>
      </Card>
      {/* <DevMode>
        <Link className="btn btn-primary btn-sm" to="/bedankt">
          Betaald
        </Link>
      </DevMode> */}
    </>
  );
};
