import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiPath } from "../utils";
import wretch from "wretch";
import uuid from "react-uuid";
import {
  createProfile,
  createRegistration,
  createRegistrationParameters,
} from "../classFactory";
import { yearsApart } from "../../utils/DateUtils";
import { MEMBER_TYPE } from "../data-constants";

const config = require("@root/config.json");

export const lookUpParent = (user_ID, year) => {
  return function lookUpParentAsync(dispatch, getState, context) {
    axios
      .post(apiPath("registration/lookupParent"), {
        user_ID,
        year,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => console.log("error"));
  };
};

export const getFullRegistration = createAsyncThunk(
  "registration/renew",
  ({ user_ID, previous }, { fulfillWithValue, rejectWithValue }) => {
    return wretch(apiPath("registration/queryAllByYear"))
      .post({
        user_ID: user_ID,
        year: config.year,
        previous,
      })
      .json((response) => {
        return fulfillWithValue({ registrations: response.registrations });
      });
  }
);

export const getPreviousRegistration = createAsyncThunk(
  "registration/previous",
  (placeHolder, { getState, fulfillWithValue, rejectWithValue }) => {
    const state = getState();
    const user_ID = state.registration.registrations.find(
      (r) => r.id === state.registration.activeRegistration
    ).user_ID;

    return wretch(apiPath("registration/queryYear"))
      .post({ user_ID, year: config.year - 1 })
      .notFound((error) => rejectWithValue({ code: 404 }))
      .json((response) => {
        return fulfillWithValue(response.registration);
      });
  }
);

export const getThisYearsRegistration = createAsyncThunk(
  "registration/current",
  ({ user_ID, year }, { fulfillWithValue, rejectWithValue }) => {
    return wretch(apiPath("registration/queryYear"))
      .post({ user_ID, year })
      .notFound((error) => rejectWithValue({ code: 404 }))
      .json((response) => {
        return fulfillWithValue(response.registration);
      });
  }
);

export const startRegistration = createAsyncThunk(
  "registration/start",
  (params, { getState, fulfillWithValue }) => {
    const user_ID = params.user_ID;

    // Check if profile is already found in cache
    const state = getState();
    const exists = state.registration.registrations.find(
      (reg) => reg.user_ID === user_ID
    );
    if (typeof exists !== "undefined") {
      return fulfillWithValue({
        user_ID,
        inCache: true,
        registrationId: exists.id,
      });
    }

    // Get user profile
    return wretch(apiPath("user/profile"))
      .post({ user_ID })
      .json((response) => {
        // Combine data from service with data sent from startRegistration (in case of prefill by id third)
        Object.assign(response, params);

        if (state.registration.temp_profile !== null) {
          Object.assign(response, state.registration.temp_profile);
        }
        return { user_ID, response };
      });

    // return function lookUpProfile(dispatch, getState, context) {
    //   axios
    //     .post(apiPath("user/getProfile"), {
    //       userID,
    //     })
    //     .then((res) => {
    //       console.log(res.data);
    //     })
    //     .catch((err) => console.log("error"));
    // };
  }
);
export const saveProfile = createAsyncThunk(
  "registration/save-profile",
  (profile, { fulfillWithValue, rejectWithValue, getState }) => {
    const state = getState();
    const user_ID = state.registration.registrations.find(
      (r) => r.id === state.registration.activeRegistration
    ).user_ID;

    const profileDetails = { ...profile };
    profileDetails.user_ID = user_ID;

    return wretch(apiPath("profile/save"))
      .post(profileDetails)
      .notFound((error) => rejectWithValue({ code: 404 }))
      .error(400, (error) => {
        // console.log(JSON.parse(error.message));
        return rejectWithValue({ code: 400, ...JSON.parse(error.message) });
      })
      .json((response) => fulfillWithValue(profile))
      .catch((error) => {
        return rejectWithValue({ code: 500 });
        // console.log(error);
      });
  }
);
// Placeholder is required or the expand of thunk feature doesn't work
export const createOrder = createAsyncThunk(
  "registration/save",
  (placeHolder, { fulfillWithValue, rejectWithValue, getState }) => {
    const state = getState();

    const user_ID = state.user.user_ID;

    // Get copy of data
    const registrationData = state.registration.registrations.map((r) => ({
      // id: r.id,
      user_ID: r.user_ID,
      is_requester: r.user_ID === user_ID,
      is_parent: r.is_parent,
      parameters: r.parameters,
    }));

    const registrationDataJSON = JSON.stringify(registrationData);
    const encodedData = btoa(registrationDataJSON);

    return wretch(apiPath("registration/save"))
      .post({ user_ID, description: encodedData })
      .notFound((error) => rejectWithValue({ code: 404 }))
      .error(400, (error) => {
        // console.log(JSON.parse(error.message));
        return rejectWithValue({ code: 400, ...JSON.parse(error.message) });
      })
      .json((response) => fulfillWithValue(response))
      .catch((error) => {
        return rejectWithValue({ code: 500 });
      });
  }
);
export const setLoggedInUserAsParent = createAsyncThunk(
  "registration/setParent",
  (placeHolder, { fulfillWithValue, rejectWithValue, getState }) => {
    const state = getState();
    const loggedInID = state.user.user_ID;

    // Find parent registration
    const parentRegistration = state.registration.registrations.find(
      (reg) => reg.user_ID === loggedInID
    );
    if (parentRegistration !== undefined) {
      return fulfillWithValue(parentRegistration.id);
    } else {
      return rejectWithValue(false);
    }
  }
);

export const checkForParentRegistration = createAsyncThunk(
  "registration/save",
  (placeHolder, { fulfillWithValue, rejectWithValue, getState }) => {
    const state = getState();

    const loggedInID = state.user.user_ID;

    // Find parent registration
    const parentRegistration = state.registration.registrations.find(
      (reg) => reg.user_ID === loggedInID
    );
    if (parentRegistration !== undefined) {
      return fulfillWithValue(true);
    } else {
      return rejectWithValue(false);
    }
  }
);

const registrationSlice = createSlice({
  name: "registration",
  initialState: {
    registeringForYear: config.year,
    temp_profile: null,
    // Store the registration you're currently editing
    activeRegistration: "", // reference to one of the entries below (uuid)
    // array of Registrations
    registrations: [],
  },
  reducers: {
    // setRegistrationUserId: (state, userId) => {
    //   state.registrations.find((r)=>r.id === )
    // }
    setTempProfile: (state, action) => {
      state.temp_profile = action.payload;
    },
    setRegistrationParameter: (state, action) => {
      const keyValue = action.payload;
      const activeRegistration = state.registrations.find(
        (reg) => reg.id === state.activeRegistration
      );
      switch (keyValue.key) {
        case "sports":
          activeRegistration.parameters.sports = keyValue.value.sports;
          activeRegistration.parameters.main_sport = keyValue.value.main_sport;
          break;
        default:
          activeRegistration.parameters[keyValue.key] = keyValue.value;
          return;
      }
    },
    setActiveRegistration: (state, action) => {
      state.activeRegistration = action.payload;
    },
    deleteRegistration: (state, action) => {
      state.registrations = state.registrations.filter(
        (reg) => reg.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startRegistration.fulfilled, (state, action) => {
        if (action.payload.inCache) {
          state.activeRegistration = action.payload.registrationId;
        } else {
          const newId = uuid();
          state.activeRegistration = newId;
          const newRegistration = createRegistration(
            newId,
            action.payload.user_ID
          );

          if (action.payload !== false) {
            newRegistration.profile = createProfile(action.payload.response);
          }
          // Prefill year
          newRegistration.parameters.year = config.year;

          state.registrations.push(newRegistration);

          // clear any temp data
          state.temp_profile = null;
        }
        // console.log("Fullfilled", action, action.payload);
      })
      .addCase(getPreviousRegistration.rejected, (state) => {
        // store in previous registration
        const currentRegistration = state.registrations.find(
          (reg) => reg.id === state.activeRegistration
        );
        const previousRegistration = createRegistrationParameters();
        currentRegistration.previousRegistration = previousRegistration;
      })
      .addCase(getPreviousRegistration.fulfilled, (state, action) => {
        // store in previous registration
        // console.log(action, action.payload);

        const currentRegistration = state.registrations.find(
          (reg) => reg.id === state.activeRegistration
        );
        const previousRegistration = createRegistrationParameters(
          action.payload
        );
        // console.log(previousRegistration);
        currentRegistration.previousRegistration = previousRegistration;
      })
      .addCase(getFullRegistration.fulfilled, (state, action) => {
        // console.log("fullfilled", action);
        // Update years and adulthood
        action.payload.registrations.forEach((reg) => {
          // Give them a unique UUID
          reg.id = uuid();

          reg.parameters.year = config.year;
          const date_of_birth = new Date(reg.profile.date_of_birth);
          const today = new Date();

          reg.parameters.adult = yearsApart(today, date_of_birth) > 18;
        });
        // Set first one as active
        state.activeRegistration = action.payload.registrations[0].id;
        state.registrations = action.payload.registrations;
      })
      .addCase(getFullRegistration.rejected, (state) => {})
      .addCase(getThisYearsRegistration.fulfilled, (state, action) => {
        // store in previous registration
        const currentRegistration = state.registrations.find(
          (reg) => reg.id === state.activeRegistration
        );
        const previousRegistration = createRegistrationParameters(
          action.payload
        );
        currentRegistration.parameters = previousRegistration;
      })
      .addCase(saveProfile.fulfilled, (state, action) => {
        const currentRegistration = state.registrations.find(
          (reg) => reg.id === state.activeRegistration
        );
        currentRegistration.profile = { ...action.payload };
      })
      .addCase(setLoggedInUserAsParent.fulfilled, (state, action) => {
        const parentRegistration = state.registrations.find(
          (reg) => reg.id === action.payload
        );
        parentRegistration.is_parent = true;
        if (parentRegistration.parameters.type === 0)
          // Could be active sporter
          parentRegistration.parameters.type = MEMBER_TYPE.OUDERLID;
      });
  },
});

export const {
  setRegistrationParameter,
  setActiveRegistration,
  setTempProfile,
  deleteRegistration,
} = registrationSlice.actions;

export default registrationSlice.reducer;
