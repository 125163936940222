import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import Card from "../../components/Card";
import { DevMode } from "../../components/DevMode";
import { getActiveProfile } from "../../store/registration/registrationSelectors";
import { setLoggedInUserAsParent } from "../../store/registration/registrationSlice";

export const BirthDateForm = () => {
  const thisYear = new Date().getFullYear();
  const dispatch = useDispatch();

  const activeProfile = useSelector(getActiveProfile);
  let defaultBirthYear = thisYear;
  if (
    activeProfile.date_of_birth !== "" &&
    typeof activeProfile.date_of_birth === "string"
  ) {
    // Try to parse from existing data
    const dateParts = activeProfile.date_of_birth.split("-");
    if (dateParts.length > 0) {
      defaultBirthYear = dateParts[0];
    }
  }
  const [birthYear, setBirthYear] = useState(defaultBirthYear);

  const history = useHistory();

  const saveBirthYear = (e) => {
    e.preventDefault();
    if (thisYear - parseInt(birthYear) < 18) {
      // history.push("/ouderlid-bevestigen");
      dispatch(setLoggedInUserAsParent());
      history.push("/ouder-informatie");
    } else history.push("/persoonlijke-gegevens");
  };

  return (
    <>
      <Card>
        <Card.Header>Geboortejaar</Card.Header>
        <Card.Body>
          <p className="mb-4">
            Om verder te gaan zouden we het geboortejaar moeten weten /
            bevestigen
          </p>
          <div className="form-control">
            <div className="form-control">
              <div className="">
                <input
                  type="text"
                  className="input input-bordered input-lg"
                  value={birthYear}
                  onChange={(e) => {
                    e.preventDefault();
                    setBirthYear(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </Card.Body>
        <Card.Actions>
          <button className="btn btn-primary" onClick={saveBirthYear}>
            Volgende
          </button>
        </Card.Actions>
      </Card>
      {/* <DevMode>
        <Link className="btn btn-primary btn-sm" to="/ouderlid-bevestigen">
          Jonger &amp; ouderlid gekend
        </Link>
        <Link className="btn btn-primary btn-sm" to="/ouder-informatie">
          Jonger &amp; ouderlid niet gekend
        </Link>
      </DevMode> */}
    </>
  );
};
