import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import FormCard from "../../../components/FormCard";
import { SpinnerOverlay } from "../../../components/SpinnerOverlay";
import { login, lookupUserBy } from "../../../store/user/userSlice";
import { loginMode } from "../IdentificationForm";
import wretch from "wretch";
import { apiPath, wpPath } from "../../../store/utils";

import HCaptcha from "@hcaptcha/react-hcaptcha";
import { Alert } from "../../../components/Alert";
import uuid from "react-uuid";
import { setTempProfile } from "../../../store/registration/registrationSlice";

export function Register({ setMode, email }) {
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email,
    password: "",
    password2: "",
    token: "",
  });

  const [callPending, setCallPending] = useState(false);
  const [errors, seterrors] = useState(null);
  const formTopRef = useRef();
  const dispatch = useDispatch();
  function onVerifyCaptcha(token) {
    setUser((user) => ({ ...user, token }));
  }
  function doRegister(e) {
    e.preventDefault();
    // First check if email account already exists
    seterrors(null);

    setCallPending(true);
    dispatch(lookupUserBy({ fieldName: "email", value: user.email }))
      .unwrap()
      .then((originalPromiseResult) => {
        seterrors([
          "Er bestaat al een account met dit email adres. Gelieve in te loggen",
        ]);
        setCallPending(false);
      })
      .catch((rejectedValue) => {
        // Unknown so register!
        // setCallPending(false);
        // Register first
        wretch(wpPath("registraties/handle-user.php"))
          .post({
            ...user,
          })
          .json((response) => {
            setCallPending(false);
            if (response.success) {
              dispatch(
                setTempProfile({
                  first_name: user.first_name,
                  last_name: user.last_name,
                  email: user.email,
                })
              );
              dispatch(login({ email: user.email, password: user.password }));
            } else {
              seterrors([
                "Er bestaat al een account met dit email adres. Gelieve in te loggen",
              ]);
              if (formTopRef.current) formTopRef.current.scrollTop = 0;
            }
          });
      });
  }

  return (
    <FormCard
      enableBack={true}
      backAction={() => {
        setMode(loginMode.START);
      }}
      onSubmit={doRegister}
      mainRef={formTopRef}
    >
      <FormCard.Header>
        <span>Aanmelden</span>
        <span className="block text-secondary">&gt;&nbsp;registratie</span>
      </FormCard.Header>

      <FormCard.Body>
        <p>Om te beginnen hebben we een aantal gegevens nodig</p>

        {errors !== null && (
          <Alert type="error" className="mb-4">
            <div>
              {errors.map((err) => (
                <span className="block" key={uuid()}>
                  {err}
                </span>
              ))}
            </div>
          </Alert>
        )}
        <div className="form-control">
          <label className="label">
            <span className="label-text">Voornaam</span>
          </label>
          <input
            type="text"
            placeholder="Tybeert"
            className="input input-bordered"
            value={user.first_name}
            onChange={(e) => {
              e.preventDefault();
              setUser((prev) => ({ ...prev, first_name: e.target.value }));
            }}
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Achternaam</span>
          </label>

          <input
            type="text"
            placeholder="De Kater"
            className="input input-bordered"
            value={user.last_name}
            onChange={(e) => {
              e.preventDefault();
              setUser((prev) => ({ ...prev, last_name: e.target.value }));
            }}
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Email</span>
          </label>

          <input
            type="email"
            placeholder="tybeert@vosreinaert.be"
            className="input input-bordered"
            value={user.email}
            onChange={(e) => {
              e.preventDefault();
              setUser((prev) => ({ ...prev, email: e.target.value }));
            }}
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Wachtwoord</span>
          </label>

          <input
            type="password"
            className="input input-bordered"
            value={user.password}
            onChange={(e) => {
              e.preventDefault();
              setUser((prev) => ({ ...prev, password: e.target.value }));
            }}
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Wachtwoord herhalen</span>
          </label>

          <input
            type="password"
            className="input input-bordered"
            value={user.password2}
            onChange={(e) => {
              e.preventDefault();
              setUser((prev) => ({ ...prev, password2: e.target.value }));
            }}
          />
        </div>
        <HCaptcha
          sitekey="bad67710-191e-498c-8da5-d9a9fdaa0ebe"
          onVerify={onVerifyCaptcha}
        />
        {/* {callPending && <SpinnerOverlay />} */}
      </FormCard.Body>

      <FormCard.Actions>
        <button
          type="submit"
          // disabled={callPending}
          className="btn btn-primary px-10"
        >
          Registreren
        </button>
      </FormCard.Actions>
    </FormCard>
  );
}
