import React, { useState } from "react";
import Card from "../../components/Card";
import { VolunteeringSelection } from "./VolunteeringSelection";
import { Link, useHistory } from "react-router-dom";
import { DevMode } from "../../components/DevMode";
import { getActiveRegistration } from "../../store/registration/registrationSelectors";
import { useDispatch, useSelector } from "react-redux";
import FormCard from "../../components/FormCard";
import { bit_clear, bit_set, bit_test, getBit } from "../../utils/BitUtils";
import { setRegistrationParameter } from "../../store/registration/registrationSlice";

export const TeamSelectionForm = ({ collection, next, back }) => {
  const registration = useSelector(getActiveRegistration);
  // TODO: Check if edit or new. Let's presume new for now
  const initialTeam = registration.parameters.team
    ? registration.parameters.team
    : registration.previousRegistration.team;

  const [team, setTeam] = useState(initialTeam);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleRadioChange = (e) => {
    setTeam(e.target.value);
  };

  const processForm = (e) => {
    e.preventDefault();
    saveForm(team);
  };

  const saveForm = (t) => {
    dispatch(
      setRegistrationParameter({
        key: "team",
        value: team,
      })
    );
    next();
  };

  return (
    <>
      <FormCard
        onSubmit={processForm}
        enableBack={true}
        backAction={() => {
          back(0);
        }}
      >
        <FormCard.Header>Team</FormCard.Header>
        <FormCard.Body>
          <p>Bij welk team speel(t) je / deze persoon in seizoen 2024-2025?</p>
          {Object.entries(collection).map(([key, value], i) => {
            return (
              <div className="form-control flex-row items-center" key={value}>
                <div className="flex-grow">
                  <label className="cursor-pointer label justify-start">
                    <input
                      type="radio"
                      group="team"
                      className="radio radio-primary mr-4"
                      checked={team === value}
                      value={value}
                      onChange={handleRadioChange}
                    />
                    <span className="label-text text-base">{value}</span>
                  </label>
                </div>
              </div>
            );
          })}
        </FormCard.Body>
        <FormCard.Actions>
          <button className="btn btn-primary">Volgende</button>
          <button
            className="btn btn-secondary"
            type="button"
            onClick={() => {
              setTeam(null);
              saveForm(null);
            }}
          >
            Niet van toepassing
          </button>
        </FormCard.Actions>
      </FormCard>
      {/* <DevMode>
        <Link className="btn btn-primary btn-sm" to="/trainer-voordelen">
          Trainer
        </Link>
        <Link className="btn btn-primary btn-sm" to="/familie-informatie">
          Niet trainer
        </Link>
      </DevMode> */}
    </>
  );
};
